export enum TaskType {
  received_corrupt_document = 'received_corrupt_document',
  unassigned_shipment = 'unassigned_shipment',
  received_duplicate_document = 'received_duplicate_document',
  shipment_data_discrepancy = 'shipment_data_discrepancy',
  unregistered_party_address = 'unregistered_party_address',
}

export enum EProcessingStatus {
  success = 'success',
  failed = 'failed',
  unreceived = 'unreceived',
}

export enum EDocumentType {
  master_bill_of_lading = 'MBL',
  house_bill_of_lading = 'HBL',
  carrier_arrival_notice = 'Carrier AN',
  ocean_arrival_notice = 'Ocean AN',
  master_air_waybill = 'MAWB',
  house_air_waybill = 'HAWB',
  air_arrival_notice = 'Air AN',
  booking_confirmation = 'BC',
  commercial_invoice = 'CI',
  packing_list = 'PL',
  isf = 'ISF',
  automated_manifest_system = 'AMS',
  pre_alert = 'Pre Alert',
  rail_arrival_notice = 'Rail AN',
  rail_arrival_notice_reminder = 'Rail AN Reminder',
  rail_pre_arrival_notice = 'Rail Pre AN',
  cargo_availability = 'Cargo Availability',
  ap_invoice = 'AP Invoice',
  osd_report = 'OSD Report',
  isf_hold_notice = 'ISF Hold Notice',
  release_order = 'Release Order',
  cbp_form_7512 = 'CBP 7512',
  canada_customs_response_report = 'CCRR',
  permit_to_transfer = 'PTT',
  emanifest = 'eManifest',
  freight_manifest = 'Freight Manifest',
  consolidation_manifest = 'Consol Manifest',
  exam_hold_release = 'Exam Hold Release',
  exam_hold_notice = 'Exam Hold Notice',
  freetime_notification = 'Freetime Notice',
  quotation = 'Quote',
  profit_loss_sharing_report = 'P/L Sharing Report',
  cfs_striping_list = 'CFS Report',
  power_of_attorney = 'POA',
  unknown = 'Unknown',
}

export enum EDocumentFieldLabel {
  shipper_name = 'Name',
  shipper_address_line_1 = 'Address line 1',
  shipper_address_line_2 = 'Address line 2',
  shipper_city = 'City',
  shipper_state = 'State',
  shipper_zip_code = 'Zip code',
  shipper_country = 'Country',
  consignee_name = 'Name',
  consignee_address_line_1 = 'Address line 1',
  consignee_address_line_2 = 'Address line 2',
  consignee_city = 'City',
  consignee_state = 'State',
  consignee_zip_code = 'Zip code',
  consignee_country = 'Country',
  'destination_agent_name' = 'Name',
  'destination_agent_address_line_1' = 'Address line 1',
  'destination_agent_address_line_2' = 'Address line 2',
  'destination_agent_city' = 'City',
  'destination_agent_state' = 'State',
  'destination_agent_zip_code' = 'Zip code',
  'destination_agent_country' = 'Country',
  'notify_party_name' = 'Name',
  'notify_party_address_line_1' = 'Address line 1',
  'notify_party_address_line_2' = 'Address line 2',
  'notify_party_city' = 'City',
  'notify_party_state' = 'State',
  'notify_party_zip_code' = 'Zip code',
  'notify_party_country' = 'Country',
  'notify_party_emails' = 'Emails',
  'freight_mode' = 'Mode',
  'freight_terms' = 'Terms',
  'freight_service' = 'Service',
  'incoterm' = 'Incoterm',
  'house_bill_of_lading_release_type' = 'Release type',
  'number_of_origin_bill_of_lading' = 'Original BOL',
  'house_bill_of_lading_number' = 'HBL #',
  'automated_manifest_system_number' = 'AMS #',
  'vessel' = 'Vessel',
  'voyage' = 'Voyage',
  'port_of_loading' = 'POL',
  'port_of_discharge' = 'POD',
  'place_of_receipt' = 'Place of receipt',
  'place_of_delivery' = 'CFS',
  'shipped_on_board_date' = 'Date',
  'estimated_time_of_departure' = 'ETD',
  'estimated_time_of_arrival' = 'ETA',
  'place_of_issue' = 'Place of issue',
  'date_of_issue' = 'Date of issue',
  'total_containers' = 'Total containers',
  'total_weight' = 'Weight',
  'total_volume' = 'Volume',
  'total_packages' = 'Inner piece count',
  'total_pallets' = 'Outer piece count',
  'weight_uom' = 'Weight UOM',
  'volume_uom' = 'Volume UOM',
  'shipment_reference_id' = 'Ref #',
  'booking_reference' = 'Booking ref',
  'ocean_carrier' = 'Carrier',
  master_bill_of_lading_number = 'Carrier BL#',
  international_maritime_organization_number = 'IMO #',
  vessel_flag = 'Flag',
  'last_foreign_port' = 'Last foreign port',
  'first_usa_port' = 'First USA port',
  'container_number' = 'Container #',
  'seal_number' = 'Seal #',
  'container_type' = 'Type',
  'package_count' = 'Inner Piece Count',
  'package_uom' = 'Inner Piece UOM',
  'pallet_count' = 'Outer Piece Count',
  'weight' = 'Weight',
  'volume' = 'Volumne',
  'hs_code' = 'HS code',
  'item_description' = 'Description',
  'total_gross_weight' = 'Gross weight',
  'total_net_weight' = 'Net weight',
  gross_weight = 'Gross weight',
  product_description = 'Product description',
  product_number = 'Product #',
  purchase_order_number = 'Order #',
  unit_quantity = 'Unit quantity',
  net_weight = 'Net weight',
  'exporter_name' = 'Name',
  'exporter_address_line_1' = 'Address line 1',
  'exporter_address_line_2' = 'Address line 2',
  'exporter_city' = 'City',
  'exporter_state' = 'State',
  'exporter_zip_code' = 'Zip code',
  exporter_country = 'Country',
  'importer_name' = 'Name',
  'importer_address_line_1' = 'Address line 1',
  'importer_address_line_2' = 'Address line 2',
  'importer_city' = 'City',
  'importer_state' = 'State',
  'importer_zip_code' = 'Zip code',
  importer_country = 'Country',
  'invoice_number' = 'Invoice #',
  'invoice_date' = 'Invoice date',
  'total_units' = 'Total units',
  'total_cost' = 'Total cost',
  'currency' = 'Currency',
  'product_hscode' = 'HS code',
  unit_price = 'Unit price',
  total_price = 'Total price',
  last_free_date = 'Last free date',
  total_charges = 'Total charges',
  'entry_number' = 'Entry #',
  'class_of_entry' = 'Entry class',
  'inbond_date' = 'Inbond date',
  'bonded_carrier' = 'Bonded carrier',
  'consignee' = 'Consignee',
  'importer' = 'Importer',
  'master_bill_of_lading_consignee_name' = 'Consignee',
  'master_bill_of_lading_payment_term' = 'Payment term',
  'master_bill_of_lading_shipper_name' = 'Shipper',
  'master_bill_of_lading_status' = 'MBL Status',
  'house_bill_of_lading_consignee_name' = 'Consignee',
  'house_bill_of_lading_payment_term' = 'P/C',
  'house_bill_of_lading_shipper_name' = 'Shipper',
  'house_bill_of_lading_status' = 'Status',
  'carrier' = 'Carrier / Liner',
  actual_time_of_departure = 'ATD',
  'estimated_time_of_departure_place_of_receipt' = 'Place of receipt ETD',
  'estimated_time_of_departure_port_of_loading' = 'Port of loading ETD',
  'estimated_time_of_arrival_port_of_discharge' = 'Port of discharge ETA',
  'estimated_time_of_arrival_place_of_delivery' = 'Place of delivery ETA',
  total_amount = 'Total amount',
  invoice_reference_id = 'Invoice ref #',
  charge_code = 'Code',
  charge_description = 'Description',
  charge_type = 'Type',
  charge_amount = 'Amount',
  charge_name = 'Name',
  charge_responsible_party = 'Responsible party',
  issue_from_name = 'Name',
  issue_from_address_line_1 = 'Address line 1',
  issue_from_address_line_2 = 'Address line 2',
  issue_from_city = 'City',
  issue_from_state = 'State',
  issue_from_zip_code = 'Zip code',
  issue_from_country = 'Country',
  issue_to = 'Issue to',
  issue_from = 'Issue from',
  print_date = 'Print date',
  doc_number = 'Doc #',
  job_number = 'Job #',
  balence_due_to_responsible_party = 'Balance due to responsible party',
  balence_due_amount = 'Balance due amount',
  issue_to_name = 'Name',
  issue_to_address_line_1 = 'Address line 1',
  issue_to_address_line_2 = 'Address line 2',
  issue_to_city = 'City',
  issue_to_state = 'State',
  issue_to_zip_code = 'Zip code',
  issue_to_country = 'Country',
  vessel_or_flight_number = 'Vessel/Flight #',
  total_profit_sharing_item_sub_total = 'Total profit sharing sub total',
  total_non_profit_sharing_item_sub_total = 'Total non profit sharing sub total',
  actual_time_of_arrival = 'ATA',
}

export enum EValidationStatus {
  SUCCESS = 'success',
  DISCREPANCY = 'discrepancy',
  /*  only have the value from one document and waiting for other documents to validate against with */
  UNVALIDATED = 'unvalidated',
  OVERRIDDEN = 'overridden',
}

interface IValidationFieldValue {
  value: string | { name: string; unloc?: string };
  shipping_document_ids: string[];
}

export interface IValidation {
  validation_type: string;
  field_values: IValidationFieldValue[];
  field_name: string;
  resolved?: boolean;
  resolved_value?: string;
  status: EValidationStatus;
}

export enum EFirebaseCollection {
  Shipments = 'shipments',
  Tasks = 'tasks',
  ShippingDocuments = 'shipping-documents',
}
