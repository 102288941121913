import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import useDetermineNewTasksAdded from 'shared/hooks/useDetermineNewTasksAdded';

interface ITaskRealtimeContext {}

const taskRealtimeContext = createContext<ITaskRealtimeContext>({} as ITaskRealtimeContext);

export const useTaskRealtimeContext = () => {
  const context = useContext(taskRealtimeContext);

  if (!context) {
    throw new Error('useTaskRealtimeContext must be used within a TaskRealtimeProvider');
  }

  return context;
};

export default function TaskRealtimeProvider({ children }: { children: ReactNode }) {
  const orgId = useSelector((state: any) => state.auth.orgId);
  const lastTimeLoadedTasks = useSelector((state: any) => state.tasks.lastTimeLoaded);

  const paramsForDeterminingNewTasksAdded = useMemo(() => {
    return { category: ['freight_forwarder_task'], org_id: orgId, lastTimeLoadedTasks };
  }, [lastTimeLoadedTasks, orgId]);
  useDetermineNewTasksAdded(paramsForDeterminingNewTasksAdded);

  return <taskRealtimeContext.Provider value={{}}>{children}</taskRealtimeContext.Provider>;
}
