import { collection, getDocs, limit, onSnapshot, orderBy, query, startAfter, where } from 'firebase/firestore';
import get from 'lodash/get';

import { EFirebaseCollection } from 'shared/constants/enums';
import { SortMode } from 'shared/constants/filters';
import { db } from '../config';
import { FetchDocumentDetailsRequest, Filter, PaginatedQueryRequest } from '../model';
import { getTotalCount } from '../utils';

const documentCollection = collection(db, EFirebaseCollection.ShippingDocuments);

export const fetchDocumentById = (request: FetchDocumentDetailsRequest, callback: any) => {
  let q = query(
    documentCollection,
    where('org_id', '==', request.orgId),
    where('id', '==', request.docId),
    orderBy('created_at', 'desc')
  );
  return onSnapshot(q, async () => {
    try {
      const querySnapshot = await getDocs(q);
      const documents = querySnapshot.docs.map((doc: any) => {
        const data = doc.data();
        return {
          ...data,
          created_at: data?.created_at?.toMillis(),
          updated_at: data?.updated_at?.toMillis(),
        };
      });
      callback?.(documents[0]);
    } catch (error: any) {
      console.error('Error in fetchDocumentById: ', error.message);
    }
  });
};

export const fetchDocumentByIds = async (orgId: string, docIds: string[]) => {
  try {
    const documentQuery = query(documentCollection, where('org_id', '==', orgId), where('id', 'in', docIds));
    const result = await getDocs(documentQuery);

    return result.docs.map((doc) => doc.data());
  } catch (error: any) {
    console.error('Error in fetchDocumentByIds: ', error.message);
    return [];
  }
};

export const fetchDocuments = async (request: PaginatedQueryRequest, callback: any) => {
  let pageCount = query(
    documentCollection,
    where('org_id', '==', request.orgId),
    orderBy(request.sort?.key || 'created_at', request.sort?.value || 'desc')
  );

  const page = request.page || 1;
  const pageSize = request.page_size || 10;

  if (request.filter?.[0]?.operation === ('include' as any)) {
    return onSnapshot(
      pageCount,
      async () => {
        try {
          const querySnapshot = await getDocs(pageCount);
          const documents = querySnapshot.docs
            .filter((doc: any) => {
              const data = doc.data();
              const filter = request.filter[0];
              return get(data, filter.key)?.toLowerCase().includes(filter.value.toString()?.toLowerCase());
            })
            .map((doc: any) => {
              const data = doc.data();
              return {
                ...data,
                created_at: data?.created_at?.toMillis(),
                updated_at: data?.updated_at?.toMillis(),
              };
            });
          const startIndex = (page - 1) * pageSize;
          const endIndex = startIndex + pageSize;
          const data = documents.slice(startIndex, endIndex);
          callback?.({
            documents: data,
            totalPages: Math.ceil(documents.length / request.page_size),
            totalData: documents.length,
          });
        } catch (error: any) {
          console.error('Error in fetchDocuments: ', error.message);
        }
      },
      (error) => {
        console.error('Error in fetchDocuments:', error.message);
      }
    );
  }

  if (request.filter?.length) {
    request.filter?.forEach((filterItem) => {
      pageCount = query(pageCount, where(filterItem.key, filterItem.operation, filterItem.value));
    });
  }
  let q = pageCount;
  if (page > 1) {
    const offset = (page - 1) * pageSize;
    const offsetQuery = query(q, limit(offset));
    const offsetDocs = await getDocs(offsetQuery);
    if (!offsetDocs.empty) {
      const lastDoc = offsetDocs.docs[offsetDocs.docs.length - 1];
      if (lastDoc) {
        q = query(q, startAfter(lastDoc), limit(pageSize));
      }
    }
  } else {
    q = query(q, limit(pageSize));
  }

  return onSnapshot(
    q,
    async () => {
      try {
        const querySnapshot = await getDocs(q);
        const documents: any[] = [];

        for (const doc of querySnapshot.docs) {
          const data = doc.data();
          const docDetails = {
            ...data,
            created_at: data.created_at?.toMillis(),
            updated_at: data?.updated_at?.toMillis(),
          };
          documents.push(docDetails);
        }

        const totalData = await getTotalCount(pageCount);

        callback?.({
          documents,
          totalPages: Math.ceil(totalData / request.page_size),
          totalData,
        });
      } catch (error: any) {
        console.error('Error in fetchDocuments: ', error.message);
      }
    },
    (error) => {
      console.error('Error in fetchDocuments: ', error.message);
    }
  );
};

export const documentStats = (request: PaginatedQueryRequest, callback?: any) => {
  let q = query(documentCollection, where('org_id', '==', request.orgId));
  const failedRecord = query(q, where('processing_result.status_code', '==', 'failed'));
  const unrecievedCount = query(q, where('processing_result.status_code', '==', 'unreceived'));

  return onSnapshot(
    q,
    async (snapShot) => {
      const failedQuerySnapshot = await getDocs(failedRecord);
      const unrecievedQuerySnapshot = await getDocs(unrecievedCount);
      callback?.({
        processing_failed: failedQuerySnapshot?.docs?.length,
        unreceived: unrecievedQuerySnapshot.docs.length,
      });
    },
    (error) => {
      console.error('Error in documentStats: ', error.message);
    }
  );
};

export const getTotalDocumentCount = async (orgId: string, callback?: (count: number) => void) => {
  let q = query(documentCollection, where('org_id', '==', orgId));
  const count = await getTotalCount(q);

  if (callback) {
    callback(count);
  }

  return count;
};

export const getAllDocuments = async ({
  orgId,
  filters,
  sort,
}: {
  orgId: string;
  filters: Filter[];
  sort?: {
    value: SortMode;
    key: string;
  };
}) => {
  try {
    let documentQuery = query(
      documentCollection,
      where('org_id', '==', orgId),
      orderBy(sort?.key || 'created_at', sort?.value || 'desc')
    );

    if (filters.length) {
      filters.forEach((filterItem) => {
        documentQuery = query(documentQuery, where(filterItem.key, filterItem.operation, filterItem.value));
      });
    }

    const result = await getDocs(documentQuery);

    return result.docs.map((doc) => doc.data());
  } catch (error: any) {
    console.error('Error in getAllDocuments: ', error.message);
    return [];
  }
};
