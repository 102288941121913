import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ActiveFiltersV2 from 'shared/components/active-filters-v2';
import { Button } from 'shared/components/button';
import DataTableV2 from 'shared/components/data-table-v2';
import DocumentChip from 'shared/components/DocumentChip';
import ExportPreparedAlert from 'shared/components/ExportPreparedAlert';
import { IFilterItem } from 'shared/components/filters-drawer';
import { documentSortingColIdMap } from 'shared/components/models/sortingMap';
import StatCard from 'shared/components/StatCard';
import { SortMode } from 'shared/constants/filters';
import { PROCESSING_STATUS_OPTIONS } from 'shared/constants/options';
import { PaginatedQueryRequest } from 'shared/firebase/model';
import { getAllShipments } from 'shared/firebase/query/shipments';
import useDocuments from 'shared/hooks/useDocuments';
import { getStorage, useLocalStorage } from 'shared/hooks/useLocalStorage';
import { DocumentStatIcon } from 'shared/icons/DocumentStatIcon';
import FileSaveIcon from 'shared/icons/FileSave';
import { updateAllClientShipments } from 'shared/reduxStore/slices/shipments';
import { AppDispatch, RootState } from 'shared/reduxStore/store';
import { DOCUMENT_TYPES_OPTIONS, getDocumentTypeDisplayName } from 'shared/services';
import { cn } from 'shared/utils';
import { countFilter } from 'shared/utils/filter';
import { ExportModal } from '../containers/Document/components/ExportModal';
import ProcessingStatus from '../containers/Document/components/ProcessingStatus';
import convertDocumentQueryRequestToFilter, {
  DocumentQueryRequest,
} from '../containers/Document/utils/query-request-to-filter';

const STORAGE_KEY = 'table-documents';
const defaultFilters: DocumentQueryRequest = {
  page_size: 10,
  page: 1,
  status: [],
  client_shipment_id: [],
  order_by: 'created_at',
  order_direction: 'desc',
  orgId: '',
};

export default function DocumentsPage() {
  const dispatch = useDispatch<AppDispatch>();
  const orgId = useSelector((state: any) => state.auth.orgId);
  const { update: updateLocalStorage } = useLocalStorage(STORAGE_KEY, {});
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isExportAlertShown, setIsExportAlertShown] = useState(false);
  const { allClientShipmentsMapById } = useSelector((state: RootState) => state.shipments);
  const {
    isLoading,
    documents,
    totalPages,
    stats,
    allDocumentsCount,
    totalData = 0,
  } = useSelector((state: RootState) => state.documents);
  const [payload, setPayload] = useState<PaginatedQueryRequest>({
    page_size: 10,
    filter: [],
    sort: {
      key: 'created_at',
      value: 'desc',
    },
    orgId: '',
  });
  const [applyFilters, setApplyFilters] = useState<DocumentQueryRequest>({
    ...defaultFilters,
  });
  const [columns, setColumns] = useState<any>([]);

  useEffect(() => {
    if (allClientShipmentsMapById) {
      setColumns([
        {
          id: 'name',
          key: 'metadata.received_email_doc_filename',
          header: 'File name',
          accessorFn: (row: any) => row?.metadata?.received_email_doc_filename,
          cell: (info: any) =>
            info.getValue() ? (
              <Link to={`/document/${info.row.original?.id}`} target="_blank" className="text-blue-400 underline">
                {info.getValue()}
              </Link>
            ) : (
              '--'
            ),
          textValue: (document: any) => document.metadata?.received_email_doc_filename || '--',
        },
        {
          id: 'documents',
          key: 'type',
          header: 'Documents',
          accessorFn: (row: any) => row.type,
          cell: (info: any) => {
            return (
              <DocumentChip
                className="w-fit"
                name={info.getValue()}
                status={info.row.original?.processing_result?.status_code}
              />
            );
          },
          textValue: (document: any) => getDocumentTypeDisplayName(document.type) || document.type,
        },
        {
          id: 'status',
          key: 'processing_result.status_code',
          accessorFn: (row: any) => row.processing_result?.status_code,
          header: 'Processing status',
          cell: (info: any) => <ProcessingStatus className="cursor-default" text={info.getValue()} />,
          textValue: (document: any) => document.processing_result?.status_code || '--',
        },
        {
          id: 'createdAt',
          key: 'created_at',
          header: 'Processing timestamp',
          accessorFn: (row: any) => row?.created_at || '--',
          cell: (info: any) => (
            <span className="cursor-default">
              {info.getValue() !== '--' ? moment(info.getValue()).format('MM/DD/YYYY HH:mm:ss') : info.getValue()}
            </span>
          ),
          textValue: (document: any) =>
            document.created_at ? moment(document.created_at.toDate()).format('MM/DD/YYYY HH:mm:ss') : '--',
        },
        {
          id: 'client_shipment_id',
          key: 'client_shipment_id',
          header: 'Client shipment ID',
          accessorFn: (row: any) => row?.shipment_ids?.[0],
          cell: (info: any) => {
            const shipmentId = info.getValue();
            const clientShipmentId =
              shipmentId && allClientShipmentsMapById
                ? allClientShipmentsMapById[shipmentId]?.client_shipment_id
                : '--';

            return shipmentId ? (
              <Link
                to={`/shipments/${shipmentId}`}
                target="_blank"
                rel="noreferrer noopener"
                className="text-blue-400 underline"
              >
                <span
                  className={cn([
                    'flex items-center gap-1 font-inter text-sm font-normal hover:underline',
                    info.row.original.status === 'completed' && 'text-gray-400',
                  ])}
                >
                  {clientShipmentId}
                </span>
              </Link>
            ) : (
              '--'
            );
          },
          textValue: (document: any) => document.client_shipment_id || document.shipment_ids?.[0] || '--',
          enableSorting: false,
        },
      ]);
    }
  }, [allClientShipmentsMapById]);

  useEffect(() => {
    if (orgId)
      getAllShipments({
        orgId,
        callback: (data: any[]) => dispatch(updateAllClientShipments(data)),
      });
  }, [dispatch, orgId]);

  useDocuments(payload);

  const isFilteringByFailed = applyFilters.status?.length === 1 && applyFilters.status?.includes('failed');
  const isFilteringBySuccess = applyFilters.status?.length === 1 && applyFilters.status?.includes('success');

  const handleApplyFilters = (filter: DocumentQueryRequest) => {
    const filters = convertDocumentQueryRequestToFilter(filter);
    const previousPage = applyFilters.page || 1;
    const page_size = filter.page_size || 10;

    setPayload({
      ...payload,
      page: filter.page || 1,
      page_size,
      sort: {
        key: (filter.order_by && (documentSortingColIdMap as any)?.[filter.order_by]) || 'created_at',
        value: (filter.order_direction || 'desc') as SortMode,
      },
      filter: filters as any,
      direction: previousPage === filter.page ? undefined : previousPage < filter.page ? 'next' : 'prev',
    });
    setApplyFilters(filter);
  };

  const handleClearFilter = (key: keyof DocumentQueryRequest, value: any) => {
    handleApplyFilters({
      ...applyFilters,
      [key]: value,
      page: 1,
    });
  };
  const handleResetFilters = () => {
    handleApplyFilters(defaultFilters);
  };

  const handleClickStatusCard = (status: string) => () => {
    if ((status === 'failed' && isFilteringByFailed) || (status === 'success' && isFilteringBySuccess)) {
      handleClearFilter('status', []);
    } else {
      handleApplyFilters({
        ...applyFilters,
        status: [status],
        page: 1,
      });
    }
  };

  const handleChangeColumnsVisibility = (columnVisibility: Record<string, boolean>) => {
    updateLocalStorage('columnVisibility', columnVisibility);
  };

  const filterItems: IFilterItem[] = [
    {
      key: 'name',
      title: 'File name',
      type: 'inputSearch',
      placeholder: 'Search file name',
      value: applyFilters.name,
      activeValue: applyFilters.name || '',
      onChange: () => {},
      onClear: () => handleClearFilter('name', ''),
      disabled: false,
      disabledChecker: (filters: any) => {
        return countFilter(filters) > 0 && !filters.name;
      },
    },
    // {
    //   key: 'client_shipment_id',
    //   title: 'Client Shipment ID',
    //   type: 'dropdown',
    //   options: allClientShipmentIds.map((id) => ({ value: id, label: id })),
    //   onChange: () => {},
    //   onClear: () => {
    //     handleClearFilter('client_shipment_id', []);
    //   },
    //   value: applyFilters.client_shipment_id || [],
    //   activeValue: applyFilters.client_shipment_id || [],
    //   placeholder: 'Search Client Shipment',
    //   disabled: false,
    //   disabledChecker: (filters: any) => {
    //     return countFilter(filters) > 0 && (!filters.client_shipment_id || filters.client_shipment_id.length === 0);
    //   },
    // },
    {
      key: 'type',
      title: 'Documents',
      type: 'dropdown',
      options: DOCUMENT_TYPES_OPTIONS,
      placeholder: 'Search documents type',
      value: applyFilters.type,
      activeValue: applyFilters.type || [],
      onChange: () => {},
      onClear: () => handleClearFilter('type', []),
      disabled: false,
      disabledChecker: (filters: any) => {
        return countFilter(filters) > 0 && (!filters.type || filters.type.length === 0);
      },
    },
    {
      key: 'status',
      title: 'Processing status',
      options: PROCESSING_STATUS_OPTIONS.slice(1),
      onChange: () => {},
      value: applyFilters.status || [],
      activeValue: applyFilters.status || [],
      type: 'checkbox',
      onClear: () => {
        handleClearFilter('status', []);
      },
      disabled: false,
      disabledChecker: (filters: any) => {
        return countFilter(filters) > 0 && (!filters.status || filters.status.length === 0);
      },
    },
    {
      key: 'createdAt',
      title: 'Processing timestamp',
      type: 'dateDropdown',
      value: undefined,
      activeValue: applyFilters.createdAt?.label || '',
      onChange(value) {},
      onClear() {
        handleClearFilter('createdAt', undefined);
      },
      onChangeComposer: (setValueFn) => (value) => {
        setValueFn((prev: any) => ({
          ...prev,
          page: 1,
          createdAt: value,
        }));
      },
      disabled: false,
      disabledChecker: (filter) => {
        return countFilter(filter) > 0 && !filter.createdAt;
      },
    },
  ];

  const activeFilterCount = useMemo(
    () =>
      filterItems.filter((item) => {
        if (!item.activeValue) return false;
        const isArray = Array.isArray(item.activeValue);
        if (isArray && item.activeValue.length === 0) return false;
        if (item.type === 'dateRange' && item.activeValue?.filter((v: any) => v).length === 0) return false;
        return true;
      }).length,
    [filterItems]
  );

  return (
    <main>
      <div className="grid grid-cols-2 gap-6 md:grid-cols-3">
        <StatCard
          icon={<DocumentStatIcon variant="failed" />}
          text="Failed processing"
          loading={isLoading}
          count={stats?.processing_failed || 0}
          onClick={handleClickStatusCard('failed')}
          active={isFilteringByFailed}
        />
        <StatCard
          icon={<DocumentStatIcon variant="completed" />}
          text="Successfully processed"
          loading={isLoading}
          onClick={handleClickStatusCard('success')}
          count={allDocumentsCount || 0 - stats?.processing_failed || 0}
          active={isFilteringBySuccess}
        />
        <StatCard
          icon={<DocumentStatIcon variant="all" />}
          text="All"
          loading={isLoading}
          count={allDocumentsCount}
          onClick={handleResetFilters}
        />
      </div>

      <DataTableV2
        tableId="documents"
        title="Documents"
        className="mt-6"
        columns={columns}
        data={documents}
        totalPages={totalPages}
        totalCount={totalData}
        loading={isLoading}
        buttonGroup={
          <Button color="white" onClick={() => setIsExportModalOpen(true)}>
            <FileSaveIcon className="!text-white" /> Export
          </Button>
        }
        applyFilter={applyFilters}
        activeFilters={
          <ActiveFiltersV2
            filterItems={filterItems}
            filters={applyFilters}
            handleApply={(filters) => {
              handleApplyFilters({
                ...applyFilters,
                ...filters,
                page: 1,
              });
            }}
            resetFilter={handleResetFilters}
          />
        }
        setFilter={setApplyFilters}
        setColumns={setColumns}
        filterItems={filterItems}
        filterCount={activeFilterCount}
        resetFilter={handleResetFilters}
        currentPage={applyFilters.page || 1}
        handleApply={handleApplyFilters}
        onRowClick={() => {}}
        onChangeColumnsVisibility={handleChangeColumnsVisibility}
        emptyContent={{
          title: countFilter(applyFilters) ? 'Result Not Found' : 'No Document Available',
          description: countFilter(applyFilters)
            ? 'Your search did not return any results.\nPlease try refining your search criteria.'
            : 'There are currently no Document. Please check back later or explore other sections.',
          action: countFilter(applyFilters) ? (
            <Button onClick={handleResetFilters} className="!px-8">
              Clear
            </Button>
          ) : undefined,
        }}
        filterAlertChecker={(filter: any) => countFilter(filter) > 0}
        filterAlert={{
          color: 'info',
          message: 'Only one filter can be used at a time.',
        }}
        activeRows={[]}
      />

      {isExportAlertShown && <ExportPreparedAlert onDismiss={() => setIsExportAlertShown(false)} />}
      <ExportModal
        isOpen={isExportModalOpen}
        setIsOpen={setIsExportModalOpen}
        columns={columns}
        filterRequest={applyFilters}
        columnVisibility={getStorage(STORAGE_KEY)?.columnVisibility ?? {}}
        onPrepareExport={() => {
          setIsExportAlertShown(true);
        }}
      />
    </main>
  );
}
