import get from 'lodash/get';
import isArray from 'lodash/isArray';
import React from 'react';

import Status from 'shared/components/status';
import { EValidationStatus } from 'shared/constants/enums';

interface Props {
  shipment: any;
}

const ShipmentDetailsHeader: React.FC<Props> = ({ shipment }) => {
  const validationStatus = shipment?.validation_result?.status_code;
  const isDiscrepancy = validationStatus === 'discrepancy';
  const records = get(shipment, 'validation_result.records', []);
  const validationStatusCount =
    isDiscrepancy && isArray(records)
      ? records.filter((record: any) => record.status === 'discrepancy').length
      : undefined;

  const rawETD = get(shipment, 'data.estimated_time_of_departure');
  const formattedETD = rawETD ? new Date(rawETD).toLocaleString() : '--';
  const rawETA = get(shipment, 'data.estimated_time_of_arrival');
  const formattedETA = rawETA ? new Date(rawETA).toLocaleString() : '--';

  return (
    <div className="border-b border-gray-200 bg-white py-4">
      <div className="container mx-auto flex flex-wrap items-center justify-between">
        <div className="flex w-full justify-between gap-10">
          <div className="flex items-center gap-6">
            <div className="flex flex-col gap-1">
              <h2 className="text-lg font-bold">Shipment details</h2>
              <p className="text-sm text-gray-700">{shipment?.client_shipment_id || 'Unassigned'}</p>
            </div>
          </div>
          <div className="flex grow flex-wrap justify-start gap-6 text-sm text-gray-600">
            <div className="flex flex-col gap-1">
              <span className="text-xs">Validation status</span>
              <Status
                type="Button"
                textClassName="!text-sm"
                text={validationStatus}
                count={validationStatusCount}
                disabled
                handleClick={() => {}}
                target={validationStatus}
              />
            </div>

            <div className="flex flex-col gap-1">
              <span className="text-xs">Mode</span>
              <span className="font-semibold text-gray-900">{shipment?.data?.load_type?.toUpperCase() || '--'}</span>
            </div>

            <div className="flex flex-col gap-1">
              <span className="text-xs">POL</span>
              <span className="font-semibold text-gray-900">{shipment?.data?.port_of_loading?.unloc || '--'}</span>
            </div>

            <div className="flex flex-col gap-1">
              <span className="text-xs">ETD</span>
              <span className="font-semibold text-gray-900">{formattedETD}</span>
            </div>

            <div className="flex flex-col gap-1">
              <span className="text-xs">POD</span>
              <span className="font-semibold text-gray-900">{shipment?.data?.port_of_discharge?.unloc}</span>
            </div>

            <div className="flex flex-col gap-1">
              <span className="text-xs">ETA</span>
              <span className="font-semibold text-gray-900">{formattedETA}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentDetailsHeader;
