import { getEndOfDay, getStartOfDay } from 'shared/utils/date';
import axios from '../axios';
import { handleApiResponse } from '../utils';
import { IFindManyTasksRequest, IFindManyTasksResponse, ITaskStatsResponse } from './tasks.types';

const servicePath = `/tasks`;

// Note: Importantly, no export endpoints here
// All API call in service

const endpoints = {
  getTasks: {
    url: `${servicePath}`,
    method: 'GET',
  },
  getTaskStats: {
    url: `${servicePath}/stats`,
    method: 'GET',
  },
  resolveTask: {
    url: `${servicePath}/:id/resolve`,
    method: 'PATCH',
  },
};

const convertParams = (params: IFindManyTasksRequest) => {
  return {
    ...params,

    ...(params?.page && { page: params.page }),
    ...(params?.page_size && { page_size: params.page_size }),
    ...(params?.order_by && {
      order_by: params.order_by === 'time_in_queue' ? 'schedule.due_at' : params.order_by,
    }),
    ...(params?.order_direction && { order_direction: params.order_direction }),
    ...(params?.category && { category: params.category.join(',') }),
    ...(params?.status && { status: params.status.join(',') }),
    ...(params?.type && { type: params.type.join(',') }),
    ...(params?.urgency && { urgency: params.urgency.join(',') }),
    ...(params?.shipping_doc_type && { shipping_doc_type: params.shipping_doc_type.join(',') }),
    ...(params?.created_at__gte && {
      created_at__gte: getStartOfDay(params.created_at__gte),
    }),
    ...(params?.created_at__gt && {
      created_at__gt: getStartOfDay(params.created_at__gt),
    }),
    ...(params?.created_at__lte && {
      created_at__lte: getEndOfDay(params.created_at__lte),
    }),
    ...(params?.created_at__lt && {
      created_at__lt: getEndOfDay(params.created_at__lt),
    }),
    ...(params?.due_date__gte && {
      due_date__gte: getStartOfDay(params.due_date__gte),
    }),
    ...(params?.due_date__gt && {
      due_date__gt: getStartOfDay(params.due_date__gt),
    }),
    ...(params?.due_date__lte && {
      due_date__lte: getEndOfDay(params.due_date__lte),
    }),
    ...(params?.due_date__lt && {
      due_date__lt: getEndOfDay(params.due_date__lt),
    }),
    ...(params?.completed_at__gte && {
      completed_at__gte: getStartOfDay(params.completed_at__gte),
    }),
    ...(params?.completed_at__gt && {
      completed_at__gt: getStartOfDay(params.completed_at__gt),
    }),
    ...(params?.completed_at__lte && {
      completed_at__lte: getEndOfDay(params.completed_at__lte),
    }),
    ...(params?.completed_at__lt && {
      completed_at__lt: getEndOfDay(params.completed_at__lt),
    }),
  };
};

export type TResolveTaskParams = { id: string; version: number; result: Record<string, any> };

export class TaskServiceClass {
  private _client = axios;

  public async getTasks(params?: IFindManyTasksRequest): Promise<IFindManyTasksResponse> {
    const res = await this._client.request({
      url: endpoints.getTasks.url,
      method: endpoints.getTasks.method,
      params: params ? convertParams(params) : {},
    });

    return handleApiResponse<IFindManyTasksResponse>(res);
  }

  public async getTaskStats(params?: IFindManyTasksRequest): Promise<ITaskStatsResponse> {
    const res = await this._client.request({
      url: endpoints.getTaskStats.url,
      method: endpoints.getTaskStats.method,
      params: params ? convertParams(params) : {},
    });

    return handleApiResponse<ITaskStatsResponse>(res);
  }

  public async resolveTask(data: TResolveTaskParams): Promise<string> {
    const res = await this._client.request({
      url: endpoints.resolveTask.url.replace(':id', data.id),
      method: endpoints.resolveTask.method,
      data,
    });

    return handleApiResponse<string>(res);
  }
}

export const taskService = new TaskServiceClass();
