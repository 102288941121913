import { EProcessingStatus, TaskType } from './enums';

export const TaskTypeLabel = {
  [TaskType.received_corrupt_document]: 'Resolve Problematic Document',
  [TaskType.unassigned_shipment]: 'Resolve Missing Shipment ID',
  [TaskType.received_duplicate_document]: 'Resolve Duplicate Document',
  [TaskType.shipment_data_discrepancy]: 'Resolve Data Discrepancy',
  [TaskType.unregistered_party_address]: 'Resolve Missing Organization Data',
};

export const DocumentProcessingStatusLabel = {
  [EProcessingStatus.success]: 'Successfully processed',
  [EProcessingStatus.failed]: 'Failed processing',
  [EProcessingStatus.unreceived]: 'Unreceived',
};
