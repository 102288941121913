import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { Button, Modal } from 'flowbite-react';
import React, { useEffect, useRef, useState } from 'react';
import { RiErrorWarningFill } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import EmailModalButton from 'shared/components/EmailModalButton';
import EmptyContent from 'shared/components/empty-content';
import { LoadingIcon } from 'shared/components/loading-icon';
import Spinner from 'shared/components/Spinner/Spinner';
import { auth } from 'shared/firebase/config';
import useTaskDetails from 'shared/hooks/useTaskDetails';
import { taskService } from 'shared/services';
import { handleErrorResponse } from 'shared/services/utils';
import DetailsHeader from './Components/TaskDetailsHeader';

const TaskDetails: React.FC = () => {
  const [refreshCount, setRefreshCount] = useState(0);
  const { taskId } = useParams<{ taskId: string }>();
  const { task, loading, error } = useTaskDetails(taskId || '', refreshCount);
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [isValidFile, setIsValidFile] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const [isFileReplaced, setIsFileReplaced] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fetchDownloadUrl = async () => {
      if (task?.result?.received_payload_gs_link) {
        try {
          const storage = getStorage();
          const fileRef = ref(storage, task.result.received_payload_gs_link);
          const url = await getDownloadURL(fileRef);
          setDownloadUrl(url);
          setIsFileReplaced(false); // Reset when task data changes
        } catch (error) {
          console.error('Error fetching download URL:', error);
        }
      }
    };

    fetchDownloadUrl();
  }, [task?.result?.received_payload_gs_link]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];

      // Allowed file types: PDF and Excel
      const allowedTypes = [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ];

      // File size limit: 20 MB
      const maxSize = 20 * 1024 * 1024; // 20 MB in bytes

      if (!allowedTypes.includes(selectedFile.type)) {
        alert('Unsupported file type. Please upload a PDF or Excel file.');
        setIsValidFile(false);
        return;
      }

      if (selectedFile.size > maxSize) {
        alert('File size exceeds 20MB limit. Please upload a smaller file.');
        setIsValidFile(false);
        return;
      }

      setFile(selectedFile);
      setIsValidFile(true); // File is valid
      setIsFileReplaced(true); // Mark as replaced
    } else {
      setIsValidFile(false); // No file selected
    }
  };

  const handleUpload = async () => {
    if (!file || !taskId) {
      console.error('File or Task ID is missing.');
      return;
    }

    if (!auth.currentUser) {
      console.error('User is not authenticated.');
      alert('You must be logged in to upload a file.');
      return;
    }

    const cleanFileName = encodeURIComponent(file.name);
    const fileName = `${taskId}:${cleanFileName}`;
    const storage = getStorage();
    const fileRef = ref(storage, fileName);

    try {
      setUploading(true);
      await uploadBytes(fileRef, file).then((snapshot) => {
        console.log('Uploaded a file!');
      });

      // Get the download URL
      const gsLink = `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/${fileRef.fullPath}`;

      // Update your backend API with the download URL
      await submitGcsLink(gsLink);

      setUploading(false);
      // Refresh task after successful upload
      setRefreshCount((prev) => prev + 1);
    } catch (err) {
      console.error('File upload failed:', err);
      setUploading(false);
    }
  };

  const submitGcsLink = async (gcsLink: string) => {
    if (!taskId) return;
    try {
      await taskService.resolveTask({
        id: taskId,
        version: task?.version,
        result: {
          received_payload_gs_link: gcsLink,
        },
      });
      setRefreshCount((prev) => prev + 1); // Refresh the task details
    } catch (error) {
      setErrorMessage(handleErrorResponse(error));
    }
  };

  const handleReplaceClick = () => {
    fileInputRef.current?.click(); // Open file dialog
  };

  const fileUrl = file ? URL.createObjectURL(file) : null; // Generate preview URL for the selected file
  const previewUrl = isFileReplaced ? fileUrl : downloadUrl;

  if (loading) return <Spinner />;

  if (error) {
    return <EmptyContent title="Error" description={`Error: ${error.message}`} />;
  }

  if (!task) {
    return <EmptyContent title="Task not found" className="py-8" />;
  }

  return (
    <div className="flex min-h-screen flex-col">
      <DetailsHeader task={task} />
      <div className="height-screen-minus-navbar container m-6 mx-auto flex flex-col items-center justify-center">
        <div className="flex h-full w-full flex-col rounded-lg border border-gray-200 bg-white">
          {!task?.result?.received_payload_gs_link && (
            <div className="border-b p-6 font-semibold text-red-800">
              <div className="flex items-center rounded-md bg-red-50 p-3 text-red-800">
                <div>
                  <p className="mb-1 flex items-center gap-2 font-bold">
                    <RiErrorWarningFill className="h-5 w-5 text-red-800" />
                    Problematic Document
                  </p>
                  <span className="text-[14px] font-light">
                    The document
                    {task.downloadUrl && (
                      <>
                        <span> </span>
                        <a href={task.downloadUrl} target="_blank" rel="noopener noreferrer" className="underline">
                          (<span className="font-bold">{task.data.email_attachment_filename}</span>)
                        </a>
                        <span> </span>
                      </>
                    )}
                    is a damaged file and cannot be processed. Please upload a new one.
                  </span>
                </div>
                <div className="ml-auto font-normal text-black">
                  <EmailModalButton task={task} />
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-1 items-center justify-center p-8">
            <input ref={fileInputRef} id="file-upload" type="file" className="hidden" onChange={handleFileChange} />
            {previewUrl ? (
              <div className="flex h-full w-full max-w-[730px] flex-col overflow-y-auto rounded-lg border border-gray-200 bg-white py-4">
                <div className="flex items-center justify-between gap-4 border-b px-6 pb-4">
                  <div className="flex items-center gap-2">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="32" height="32" rx="16" fill="#F3F4F6" />
                      <path
                        d="M13.4997 12V8.104C13.098 8.2144 12.7255 8.4112 12.4222 8.7032L10.0655 10.9656C9.76217 11.2568 9.55717 11.6144 9.44134 12H13.4997Z"
                        fill="#1F2A37"
                      />
                      <path
                        d="M21.0547 8H15.1663V12C15.1663 12.8824 14.4188 13.6 13.4997 13.6H9.33301V22.4C9.33301 23.2824 10.0555 24 10.9447 24H21.0555C21.9438 24 22.6663 23.2824 22.6663 22.4V9.6C22.6663 8.7176 21.9438 8 21.0547 8ZM19.0888 16.5656L15.7555 19.7656C15.593 19.9216 15.3797 20 15.1663 20C14.953 20 14.7397 19.9216 14.5772 19.7656L12.9105 18.1656C12.5847 17.8528 12.5847 17.3472 12.9105 17.0344C13.2363 16.7216 13.763 16.7216 14.0888 17.0344L15.1663 18.0688L17.9105 15.4344C18.2363 15.1216 18.763 15.1216 19.0888 15.4344C19.4147 15.7472 19.4147 16.2528 19.0888 16.5656Z"
                        fill="#1F2A37"
                      />
                    </svg>
                    <span className="text-lg font-semibold text-gray-900">Uploaded Document</span>
                  </div>
                  <Button
                    className="ml-auto gap-2 rounded-lg border border-gray-200 bg-white text-black"
                    onClick={handleReplaceClick}
                    disabled={task.status === 'completed'}
                  >
                    <div className="flex items-center gap-2">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1198_1000)">
                          <path
                            d="M9.3242 2.64222L6.9242 0.180785C6.86847 0.123479 6.80225 0.0780124 6.72936 0.0469903C6.65647 0.0159683 6.57832 0 6.4994 0C6.42048 0 6.34233 0.0159683 6.26944 0.0469903C6.19655 0.0780124 6.13034 0.123479 6.0746 0.180785L3.6746 2.64222C3.56209 2.75777 3.49895 2.91442 3.49907 3.07772C3.49918 3.24101 3.56254 3.39757 3.6752 3.51296C3.78786 3.62834 3.94061 3.6931 4.09982 3.69299C4.25904 3.69287 4.4117 3.62789 4.5242 3.51234L5.9 2.10132V7.3848C5.9 7.54801 5.96321 7.70452 6.07574 7.81993C6.18826 7.93533 6.34087 8.00016 6.5 8.00016C6.65913 8.00016 6.81174 7.93533 6.92426 7.81993C7.03679 7.70452 7.1 7.54801 7.1 7.3848V2.10132L8.4758 3.51234C8.58896 3.62444 8.74052 3.68646 8.89784 3.68506C9.05516 3.68366 9.20565 3.61894 9.31689 3.50485C9.42814 3.39075 9.49124 3.23641 9.49261 3.07507C9.49397 2.91372 9.4335 2.75828 9.3242 2.64222Z"
                            fill="#111928"
                          />
                          <path
                            d="M11.3 7.07712H8.3V7.3848C8.3 7.87441 8.11036 8.34397 7.77279 8.69018C7.43523 9.03638 6.97739 9.23088 6.5 9.23088C6.02261 9.23088 5.56477 9.03638 5.22721 8.69018C4.88964 8.34397 4.7 7.87441 4.7 7.3848V7.07712H1.7C1.38174 7.07712 1.07652 7.20679 0.851472 7.43759C0.626428 7.6684 0.5 7.98143 0.5 8.30784V10.7693C0.5 11.0957 0.626428 11.4087 0.851472 11.6395C1.07652 11.8703 1.38174 12 1.7 12H11.3C11.6183 12 11.9235 11.8703 12.1485 11.6395C12.3736 11.4087 12.5 11.0957 12.5 10.7693V8.30784C12.5 7.98143 12.3736 7.6684 12.1485 7.43759C11.9235 7.20679 11.6183 7.07712 11.3 7.07712ZM9.8 10.7693C9.622 10.7693 9.44799 10.7151 9.29999 10.6137C9.15198 10.5123 9.03663 10.3681 8.96851 10.1995C8.90039 10.0308 8.88257 9.84522 8.91729 9.66617C8.95202 9.48711 9.03774 9.32264 9.1636 9.19355C9.28947 9.06446 9.44984 8.97655 9.62442 8.94094C9.799 8.90532 9.97996 8.9236 10.1444 8.99346C10.3089 9.06333 10.4494 9.18164 10.5483 9.33343C10.6472 9.48522 10.7 9.66368 10.7 9.84624C10.7 10.091 10.6052 10.3258 10.4364 10.4989C10.2676 10.672 10.0387 10.7693 9.8 10.7693Z"
                            fill="#111928"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1198_1000">
                            <rect width="12" height="12" fill="white" transform="translate(0.5)" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="text-xs">Replace</span>
                    </div>
                  </Button>
                  <Button
                    className="gap-2 rounded-lg border border-gray-200 bg-white text-black"
                    onClick={() => setShowModal(true)}
                    disabled={task.status === 'completed'}
                  >
                    <div className="flex items-center gap-2">
                      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.0185 3.36842H11.7593V1.68421C11.7593 1.23753 11.5876 0.809144 11.282 0.493294C10.9764 0.177443 10.5619 0 10.1297 0H6.87039C6.43819 0 6.02368 0.177443 5.71807 0.493294C5.41245 0.809144 5.24076 1.23753 5.24076 1.68421V3.36842H1.9815C1.7654 3.36842 1.55815 3.45714 1.40534 3.61507C1.25253 3.77299 1.16669 3.98719 1.16669 4.21053C1.16669 4.43387 1.25253 4.64806 1.40534 4.80598C1.55815 4.96391 1.7654 5.05263 1.9815 5.05263H2.79632V14.3158C2.79632 14.7625 2.96801 15.1909 3.27362 15.5067C3.57924 15.8226 3.99374 16 4.42595 16H12.5741C13.0063 16 13.4208 15.8226 13.7264 15.5067C14.032 15.1909 14.2037 14.7625 14.2037 14.3158V5.05263H15.0185C15.2346 5.05263 15.4419 4.96391 15.5947 4.80598C15.7475 4.64806 15.8334 4.43387 15.8334 4.21053C15.8334 3.98719 15.7475 3.77299 15.5947 3.61507C15.4419 3.45714 15.2346 3.36842 15.0185 3.36842ZM6.87039 1.68421H10.1297V3.36842H6.87039V1.68421ZM12.5741 14.3158H4.42595V5.05263H12.5741V14.3158Z"
                          fill="#374151"
                        />
                        <path
                          d="M6.87039 5.89474C6.65429 5.89474 6.44704 5.98346 6.29423 6.14138C6.14142 6.29931 6.05558 6.5135 6.05558 6.73684V12.6316C6.05558 12.8549 6.14142 13.0691 6.29423 13.227C6.44704 13.385 6.65429 13.4737 6.87039 13.4737C7.08649 13.4737 7.29374 13.385 7.44655 13.227C7.59936 13.0691 7.68521 12.8549 7.68521 12.6316V6.73684C7.68521 6.5135 7.59936 6.29931 7.44655 6.14138C7.29374 5.98346 7.08649 5.89474 6.87039 5.89474Z"
                          fill="#374151"
                        />
                        <path
                          d="M10.1297 5.89474C9.91355 5.89474 9.7063 5.98346 9.55349 6.14138C9.40068 6.29931 9.31483 6.5135 9.31483 6.73684V12.6316C9.31483 12.8549 9.40068 13.0691 9.55349 13.227C9.7063 13.385 9.91355 13.4737 10.1297 13.4737C10.3458 13.4737 10.553 13.385 10.7058 13.227C10.8586 13.0691 10.9445 12.8549 10.9445 12.6316V6.73684C10.9445 6.5135 10.8586 6.29931 10.7058 6.14138C10.553 5.98346 10.3458 5.89474 10.1297 5.89474Z"
                          fill="#374151"
                        />
                      </svg>
                    </div>
                  </Button>
                </div>
                <div className="demo-image mt-4 w-full flex-1 px-4">
                  <object
                    data={previewUrl}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                    className="h-full w-full object-contain"
                  >
                    <p>
                      <a href={previewUrl}>See PDF</a>
                    </p>
                  </object>
                </div>
              </div>
            ) : (
              <div className="flex h-full max-h-[80%] w-full max-w-[80%] cursor-pointer flex-col items-center justify-center rounded-md border-2 border-dashed border-gray-200 bg-gray-50 p-12 hover:border-gray-500">
                <label htmlFor="file-upload" className="w-full cursor-pointer text-center">
                  <div className="mb-3 flex justify-center">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M18 11.7952H13V12.308C12.9973 12.8496 12.8536 13.3807 12.584 13.8464H18V17.9488H2V13.8464H7.416C7.14635 13.3807 7.00275 12.8496 7 12.308V11.7952H2C1.46957 11.7952 0.960859 12.0113 0.585786 12.396C0.210714 12.7807 0 13.3024 0 13.8464V17.9488C0 18.4928 0.210714 19.0145 0.585786 19.3992C0.960859 19.7839 1.46957 20 2 20H18C18.5304 20 19.0391 19.7839 19.4142 19.3992C19.7893 19.0145 20 18.4928 20 17.9488V13.8464C20 13.3024 19.7893 12.7807 19.4142 12.396C19.0391 12.0113 18.5304 11.7952 18 11.7952Z"
                        fill="#9CA3AF"
                      />
                      <path
                        d="M6.707 5.8539L9 3.5022V12.308C9 12.58 9.10536 12.8409 9.29289 13.0332C9.48043 13.2255 9.73478 13.3336 10 13.3336C10.2652 13.3336 10.5196 13.2255 10.7071 13.0332C10.8946 12.8409 11 12.58 11 12.308V3.5022L13.293 5.8539C13.4816 6.04073 13.7342 6.1441 13.9964 6.14176C14.2586 6.13943 14.5094 6.03157 14.6948 5.84141C14.8802 5.65126 14.9854 5.39402 14.9877 5.12511C14.99 4.85621 14.8892 4.59714 14.707 4.40371L10.707 0.301308C10.6141 0.205798 10.5038 0.130021 10.3823 0.0783172C10.2608 0.0266138 10.1305 0 9.999 0C9.86747 0 9.73722 0.0266138 9.61573 0.0783172C9.49424 0.130021 9.38389 0.205798 9.291 0.301308L5.291 4.40371C5.10349 4.59629 4.99826 4.85737 4.99844 5.12953C4.99863 5.40169 5.10423 5.66262 5.292 5.85493C5.47977 6.04724 5.73434 6.15517 5.99971 6.15498C6.26507 6.15478 6.51949 6.04648 6.707 5.8539Z"
                        fill="#9CA3AF"
                      />
                      <path
                        d="M15 16.9232C15.5523 16.9232 16 16.464 16 15.8976C16 15.3312 15.5523 14.872 15 14.872C14.4477 14.872 14 15.3312 14 15.8976C14 16.464 14.4477 16.9232 15 16.9232Z"
                        fill="#9CA3AF"
                      />
                    </svg>
                  </div>
                  <div className="mb-2 text-gray-500">
                    <span className="font-semibold">Click to upload</span> or drag and drop
                  </div>
                  <div className="mb-4 text-xs text-gray-500">File Format: pdf, xlsx, docx (max 2 mb)</div>
                  <div className="mx-auto flex w-fit items-center gap-2 rounded-lg bg-black px-4 py-2 text-white">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_401_19432)">
                        <path
                          d="M6.10007 11.7C4.99248 11.7 3.90976 11.3716 2.98884 10.7562C2.06791 10.1409 1.35014 9.26629 0.926282 8.24302C0.502426 7.21976 0.391526 6.09379 0.607606 5.00749C0.823686 3.9212 1.35704 2.92338 2.14022 2.1402C2.92341 1.35703 3.92124 0.823682 5.00755 0.607605C6.09386 0.391528 7.21984 0.502426 8.24312 0.926277C9.2664 1.35013 10.141 2.06789 10.7564 2.98881C11.3717 3.90972 11.7001 4.99242 11.7001 6.1C11.6985 7.5847 11.1079 9.00811 10.0581 10.058C9.00822 11.1078 7.58479 11.6983 6.10007 11.7ZM6.10007 1.9C5.26938 1.9 4.45734 2.14633 3.76665 2.60783C3.07595 3.06933 2.53762 3.72528 2.21973 4.49273C1.90184 5.26018 1.81866 6.10466 1.98072 6.91938C2.14278 7.7341 2.5428 8.48247 3.13018 9.06985C3.71757 9.65723 4.46595 10.0572 5.28068 10.2193C6.09541 10.3814 6.9399 10.2982 7.70736 9.98029C8.47482 9.6624 9.13077 9.12408 9.59228 8.43339C10.0538 7.74271 10.3001 6.93068 10.3001 6.1C10.299 4.98643 9.85614 3.91879 9.06872 3.13138C8.2813 2.34397 7.21365 1.90111 6.10007 1.9Z"
                          fill="white"
                        />
                        <path
                          d="M13.8001 14.5C13.6145 14.5 13.4365 14.4262 13.3052 14.2949L10.5052 11.4949C10.3777 11.3629 10.3071 11.1861 10.3087 11.0025C10.3103 10.819 10.3839 10.6434 10.5137 10.5136C10.6435 10.3838 10.8191 10.3102 11.0026 10.3086C11.1862 10.307 11.363 10.3776 11.495 10.5051L14.295 13.3051C14.3929 13.403 14.4596 13.5277 14.4866 13.6635C14.5136 13.7992 14.4997 13.94 14.4467 14.0679C14.3938 14.1957 14.3041 14.3051 14.189 14.382C14.0739 14.4589 13.9386 14.5 13.8001 14.5Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_401_19432">
                          <rect width="14" height="14" fill="white" transform="translate(0.5 0.5)" />
                        </clipPath>
                      </defs>
                    </svg>
                    Browse File
                  </div>
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 mt-auto w-full border-t border-gray-200 bg-white p-4">
        <div className="flex flex-col items-center justify-end gap-1">
          {errorMessage && <p className="w-full text-right text-red-500">{errorMessage}</p>}
          <div className="flex w-full flex-row items-center justify-end gap-6">
            <button
              className={twMerge([
                'flex flex-row gap-2 rounded-lg border px-4 py-2 text-white',
                isValidFile ? 'bg-black' : 'bg-gray-400',
              ])}
              onClick={handleUpload}
              disabled={!isValidFile || uploading || task?.status === 'completed'} // Disabled if file is invalid or uploading or task is completed
            >
              {uploading && <LoadingIcon className="h-5 w-5 fill-white text-gray-200" />}
              {uploading ? 'Uploading...' : 'Confirm'}
            </button>
          </div>
        </div>
      </div>
      <Modal onClose={() => setShowModal(false)} popup size="xs" show={showModal} position="center">
        <Modal.Header title="Delete document?" className="relative z-50"></Modal.Header>
        <Modal.Body className="relative -mt-8 rounded-lg bg-white p-4 pt-0 text-center shadow sm:p-5">
          <h2 className="mb-4 text-center text-xl font-bold">Delete document?</h2>
          <p className="mb-6 text-lg text-gray-500 dark:text-gray-300">
            Are you sure you want to delete this document?
          </p>
          <div className="flex items-center justify-center space-x-4">
            <Button
              className="gap-2 rounded-lg border border-red-700 bg-red-700 text-white"
              onClick={() => {
                setFile(null);
                setIsValidFile(false);
                setShowModal(false);
              }}
            >
              <div className="flex items-center gap-2">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.0185 3.36842H11.7593V1.68421C11.7593 1.23753 11.5876 0.809144 11.282 0.493294C10.9764 0.177443 10.5619 0 10.1297 0H6.87039C6.43819 0 6.02368 0.177443 5.71807 0.493294C5.41245 0.809144 5.24076 1.23753 5.24076 1.68421V3.36842H1.9815C1.7654 3.36842 1.55815 3.45714 1.40534 3.61507C1.25253 3.77299 1.16669 3.98719 1.16669 4.21053C1.16669 4.43387 1.25253 4.64806 1.40534 4.80598C1.55815 4.96391 1.7654 5.05263 1.9815 5.05263H2.79632V14.3158C2.79632 14.7625 2.96801 15.1909 3.27362 15.5067C3.57924 15.8226 3.99374 16 4.42595 16H12.5741C13.0063 16 13.4208 15.8226 13.7264 15.5067C14.032 15.1909 14.2037 14.7625 14.2037 14.3158V5.05263H15.0185C15.2346 5.05263 15.4419 4.96391 15.5947 4.80598C15.7475 4.64806 15.8334 4.43387 15.8334 4.21053C15.8334 3.98719 15.7475 3.77299 15.5947 3.61507C15.4419 3.45714 15.2346 3.36842 15.0185 3.36842ZM6.87039 1.68421H10.1297V3.36842H6.87039V1.68421ZM12.5741 14.3158H4.42595V5.05263H12.5741V14.3158Z"
                    fill="#fff"
                  />
                  <path
                    d="M6.87039 5.89474C6.65429 5.89474 6.44704 5.98346 6.29423 6.14138C6.14142 6.29931 6.05558 6.5135 6.05558 6.73684V12.6316C6.05558 12.8549 6.14142 13.0691 6.29423 13.227C6.44704 13.385 6.65429 13.4737 6.87039 13.4737C7.08649 13.4737 7.29374 13.385 7.44655 13.227C7.59936 13.0691 7.68521 12.8549 7.68521 12.6316V6.73684C7.68521 6.5135 7.59936 6.29931 7.44655 6.14138C7.29374 5.98346 7.08649 5.89474 6.87039 5.89474Z"
                    fill="#fff"
                  />
                  <path
                    d="M10.1297 5.89474C9.91355 5.89474 9.7063 5.98346 9.55349 6.14138C9.40068 6.29931 9.31483 6.5135 9.31483 6.73684V12.6316C9.31483 12.8549 9.40068 13.0691 9.55349 13.227C9.7063 13.385 9.91355 13.4737 10.1297 13.4737C10.3458 13.4737 10.553 13.385 10.7058 13.227C10.8586 13.0691 10.9445 12.8549 10.9445 12.6316V6.73684C10.9445 6.5135 10.8586 6.29931 10.7058 6.14138C10.553 5.98346 10.3458 5.89474 10.1297 5.89474Z"
                    fill="#fff"
                  />
                </svg>
                <span>Delete</span>
              </div>
            </Button>
            <button
              color="white"
              onClick={() => setShowModal(false)}
              className="hover:text-gray-900 focus:ring-blue-300 dark:bg-gray-700 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-600 [&>span]:text-gray-500 dark:[&>span]:bg-gray-700 dark:[&>span]:text-gray-300"
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TaskDetails;
