import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  variant: 'overdue' | 'dueToday' | 'incomplete' | 'all';
};

export function TaskStatIcon({ variant, ...props }: Props) {
  switch (variant) {
    case 'overdue':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path d="M7 16H11" stroke="#111928" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M7 11H15" stroke="#111928" strokeWidth="1.5" strokeLinecap="round" />
          <path
            d="M6.5 3.5C4.9442 3.54667 4.01661 3.71984 3.37477 4.36227C2.49609 5.24177 2.49609 6.6573 2.49609 9.48836V15.9944C2.49609 18.8255 2.49609 20.241 3.37477 21.1205C4.25345 22 5.66767 22 8.49609 22H12M15.4922 3.5C17.048 3.54667 17.9756 3.71984 18.6174 4.36228C19.4961 5.24177 19.4961 6.6573 19.4961 9.48836V12.5"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M6.49609 3.75C6.49609 2.7835 7.2796 2 8.24609 2H13.7461C14.7126 2 15.4961 2.7835 15.4961 3.75C15.4961 4.7165 14.7126 5.5 13.7461 5.5H8.24609C7.2796 5.5 6.49609 4.7165 6.49609 3.75Z"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M17 22.9999C19.7614 22.9999 22 20.7613 22 17.9999C22 15.2385 19.7614 12.9999 17 12.9999C14.2386 12.9999 12 15.2385 12 17.9999C12 20.7613 14.2386 22.9999 17 22.9999Z"
            stroke="#141B34"
            strokeWidth="1.5"
          />
          <path
            d="M16.9961 20.5H17.0021"
            stroke="#141B34"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17 17.9999V15.9999"
            stroke="#141B34"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'dueToday':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path d="M7 16H11" stroke="#111928" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M7 11H15" stroke="#111928" strokeWidth="1.5" strokeLinecap="round" />
          <path
            d="M6.5 3.5C4.9442 3.54667 4.01661 3.71984 3.37477 4.36227C2.49609 5.24177 2.49609 6.6573 2.49609 9.48836V15.9944C2.49609 18.8255 2.49609 20.241 3.37477 21.1205C4.25345 22 5.66767 22 8.49609 22H12M15.4922 3.5C17.048 3.54667 17.9756 3.71984 18.6174 4.36228C19.4961 5.24177 19.4961 6.6573 19.4961 9.48836V12.5"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M6.49609 3.75C6.49609 2.7835 7.2796 2 8.24609 2H13.7461C14.7126 2 15.4961 2.7835 15.4961 3.75C15.4961 4.7165 14.7126 5.5 13.7461 5.5H8.24609C7.2796 5.5 6.49609 4.7165 6.49609 3.75Z"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M16.5415 22.9998C19.2798 22.9998 21.4998 20.7799 21.4998 18.0416C21.4998 15.3032 19.2798 13.0833 16.5415 13.0833C13.8031 13.0833 11.5833 15.3032 11.5833 18.0416C11.5833 20.7799 13.8031 22.9998 16.5415 22.9998Z"
            stroke="#141B34"
            strokeWidth="1.37305"
          />
          <path
            d="M16 16.3335V18.5002L17.0833 19.5835"
            stroke="#141B34"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'incomplete':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path d="M7 16H11" stroke="#111928" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M7 11H15" stroke="#111928" strokeWidth="1.5" strokeLinecap="round" />
          <path
            d="M6.5 3.5C4.9442 3.54667 4.01661 3.71984 3.37477 4.36227C2.49609 5.24177 2.49609 6.6573 2.49609 9.48836V15.9944C2.49609 18.8255 2.49609 20.241 3.37477 21.1205C4.25345 22 5.66767 22 8.49609 22H12M15.4922 3.5C17.048 3.54667 17.9756 3.71984 18.6174 4.36228C19.4961 5.24177 19.4961 6.6573 19.4961 9.48836V12.5"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M6.49609 3.75C6.49609 2.7835 7.2796 2 8.24609 2H13.7461C14.7126 2 15.4961 2.7835 15.4961 3.75C15.4961 4.7165 14.7126 5.5 13.7461 5.5H8.24609C7.2796 5.5 6.49609 4.7165 6.49609 3.75Z"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M18.4997 19.5L15.5 16.5M15.5003 19.5L18.5 16.5"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22 18C22 15.2386 19.7614 13 17 13C14.2386 13 12 15.2386 12 18C12 20.7614 14.2386 23 17 23C19.7614 23 22 20.7614 22 18Z"
            stroke="#111928"
            strokeWidth="1.5"
          />
        </svg>
      );

    case 'all':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            d="M2 12.5C2 8.02166 2 5.78249 3.17157 4.39124C4.34315 3 6.22877 3 10 3C13.7712 3 15.6568 3 16.8285 4.39124C18 5.78249 18 8.02166 18 12.5C18 16.9783 18 19.2175 16.8285 20.6088C15.6568 22 13.7712 22 10 22C6.22877 22 4.34315 22 3.17157 20.6088C2 19.2175 2 16.9783 2 12.5Z"
            fill="white"
            stroke="#141B34"
            strokeWidth="1.5"
          />
          <path
            d="M4.5 9.36842C4.5 5.89491 4.5 4.15816 5.52513 3.07908C6.55025 2 8.20017 2 11.5 2H14.5C17.7998 2 19.4497 2 20.4749 3.07908C21.5 4.15816 21.5 5.89491 21.5 9.36842V14.6316C21.5 18.1051 21.5 19.8418 20.4749 20.9209C19.4497 22 17.7998 22 14.5 22H11.5C8.20017 22 6.55025 22 5.52513 20.9209C4.5 19.8418 4.5 18.1051 4.5 14.6316V9.36842Z"
            fill="white"
            stroke="#141B34"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 2L9.0822 2.4932C9.28174 3.69044 9.38151 4.28906 9.80113 4.64453C10.2208 5 10.8276 5 12.0414 5H13.9586C15.1724 5 15.7793 5 16.1989 4.64453C16.6185 4.28906 16.7183 3.69044 16.9178 2.4932L17 2"
            fill="white"
          />
          <path
            d="M9 2L9.0822 2.4932C9.28174 3.69044 9.38151 4.28906 9.80113 4.64453C10.2208 5 10.8276 5 12.0414 5H13.9586C15.1724 5 15.7793 5 16.1989 4.64453C16.6185 4.28906 16.7183 3.69044 16.9178 2.4932L17 2"
            stroke="#141B34"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M9 16H13H9ZM9 11H17H9Z" fill="white" />
          <path d="M9 16H13M9 11H17" stroke="#141B34" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
      );

    default:
      return null;
  }
}
