import { ShipmentDetailFieldLabel } from 'containers/Shipments/Details/model';
import get from 'lodash/get';
import React from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'shared/components/button';
import { CheckboxField } from 'shared/components/checkbox';
import Drawer from 'shared/components/drawer';
import { Fieldset, Label } from 'shared/components/fieldset';
import { Input } from 'shared/components/input';
import { EDocumentType, IValidation } from 'shared/constants/enums';
import { TDocumentType } from 'shared/constants/types';
import CheckCircleIcon from 'shared/icons/CheckCircleIcon';
import ExclamationIcon from 'shared/icons/ExclamationIcon';
import { RootState } from 'shared/reduxStore/store';

type Props = {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  fieldValidation: IValidation | null;
  inputData: {
    value: string;
    disabled: boolean;
  };
  setInputData: React.Dispatch<React.SetStateAction<{ value: string; disabled: boolean }>>;
  onConfirm: () => void;
  onCancel: () => void;
};

const ShipmentValidationDetailsDrawer = ({
  isOpen,
  setOpen,
  fieldValidation,
  inputData,
  setInputData,
  onConfirm,
  onCancel,
}: Props) => {
  const { shipment, overriddenShipmentDetails } = useSelector((state: RootState) => state.shipmentDetails);

  const isSubmitting = get(overriddenShipmentDetails, 'loader');
  const submittedMessage = get(overriddenShipmentDetails, 'response.message');
  const submittedStatus = get(overriddenShipmentDetails, 'response.status');

  return (
    <Drawer
      open={isOpen}
      setOpen={setOpen}
      body={
        <>
          <section className={`validation-form !h-[calc(100%-100px)] max-h-[100%] overflow-y-auto py-5`}>
            <p className="text-lg font-semibold">
              {ShipmentDetailFieldLabel[fieldValidation?.field_name as keyof typeof ShipmentDetailFieldLabel]}
            </p>
            <div className="mt-2">
              <label className="mt-2">Correct value to use</label>
              <div className="flex items-center">
                <Input
                  className={`mt-1 w-[75%]`}
                  type="text"
                  disabled={inputData?.disabled}
                  value={inputData.value}
                  onChange={(e) =>
                    setInputData((d) => ({
                      ...d,
                      value: e.target.value,
                    }))
                  }
                />
                <Fieldset>
                  <CheckboxField className="!gap-x-[4px] pl-3">
                    <input
                      type="checkbox"
                      className="focus:!text-none text-zinc-950 ring-1"
                      checked={!inputData?.disabled}
                      disabled={isSubmitting}
                      onChange={(e) => {
                        setInputData((prev) => {
                          const fieldValue = fieldValidation?.field_values[0]?.value;
                          return {
                            ...prev,
                            disabled: !e.target.checked,
                            value:
                              !e.target.checked && !prev.value
                                ? (typeof fieldValue === 'object' ? fieldValue?.name : fieldValue) || ''
                                : prev.value,
                          };
                        });
                      }}
                      value={inputData?.value}
                    />
                    <Label>Override</Label>
                  </CheckboxField>
                </Fieldset>
              </div>
              <div className="mt-5 grid grid-cols-12 gap-x-6 gap-y-2">
                <div className="col-span-5 mt-2">
                  <p className="text-sm font-semibold">Documents</p>
                </div>
                <div className="col-span-7 mt-2">
                  <p className="text-sm font-semibold">Values</p>
                </div>
                {fieldValidation?.field_values?.map((el, index) => {
                  const elValue = typeof el.value === 'object' ? el.value?.name : el.value;
                  const isSelectedValueSame = inputData?.value === elValue;

                  return (
                    <React.Fragment key={index}>
                      <div className="col-span-5 mt-2 rounded-lg p-2 shadow">
                        {el.shipping_document_ids?.map((id) => {
                          const doc = shipment.shipping_documents.filter((doc: any) => doc.id === id);
                          return (
                            <p className="truncate text-sm" key={id}>
                              {EDocumentType[doc[0]?.type as TDocumentType]}
                            </p>
                          );
                        })}
                      </div>
                      <button
                        className={`col-span-7 mt-2 rounded-lg p-2 shadow ${isSelectedValueSame ? 'bg-zinc-950' : ''}`}
                        onClick={() => {
                          if (isSubmitting) return;
                          setInputData({ value: elValue, disabled: true });
                        }}
                        disabled={isSubmitting}
                      >
                        <p className={`text-sm ${isSelectedValueSame ? 'text-white' : ''}`}>{elValue}</p>
                      </button>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </section>
          {submittedMessage && (
            <section className="right-0 h-[50px] w-full bg-white py-2">
              <div className="mt-2 flex items-center justify-between gap-5 bg-white">
                <div>
                  {submittedStatus && (
                    <span className="flex items-center gap-x-3">
                      {submittedStatus === 'success' ? <CheckCircleIcon /> : <ExclamationIcon />}
                      <div>
                        <p className="text-base/6">{submittedMessage}</p>
                      </div>
                    </span>
                  )}
                </div>
              </div>
            </section>
          )}
        </>
      }
      title="Validation details"
      footer={
        <div>
          <Button plain onClick={onCancel} className="mr-2">
            Cancel
          </Button>
          <Button
            disabled={!inputData.value || overriddenShipmentDetails?.loader}
            onClick={onConfirm}
            isLoading={overriddenShipmentDetails?.loader}
          >
            Confirm
          </Button>
        </div>
      }
    />
  );
};

export default ShipmentValidationDetailsDrawer;
