// disable eslint the whole file
// eslint-disable
import { Label } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import FileSaver from 'file-saver';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';

import { Button } from 'shared/components/button';
import { Checkbox, CheckboxField, CheckboxGroup } from 'shared/components/checkbox';
import { Dialog, DialogActions, DialogBody, DialogTitle } from 'shared/components/dialog';
import { Radio, RadioField, RadioGroup } from 'shared/components/radio';
import { QueryKey } from 'shared/constants/queryKeys';
import { Filter } from 'shared/firebase/model';
import { getAllDocuments } from 'shared/firebase/query/documents';
import convertDocumentQueryRequestToFilter, { DocumentQueryRequest } from '../utils/query-request-to-filter';

interface ExportModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  columns?: any[];
  filterRequest: DocumentQueryRequest;
  columnVisibility?: { [key: string]: boolean };
  onPrepareExport?: () => void;
  onExportFinished?: () => void;
}

type DownloadAsOption = 'CSV' | 'Excel';

export const ExportModal = ({
  isOpen,
  setIsOpen,
  columns,
  filterRequest,
  columnVisibility,
  onPrepareExport,
  onExportFinished,
}: ExportModalProps) => {
  const orgId = useSelector((state: any) => state.auth.orgId);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [selectedFormat, setSelectedFormat] = useState<DownloadAsOption>('CSV');

  const downloadAsOptions: DownloadAsOption[] = ['CSV', 'Excel'];

  const { mutate: getAllDocumentsMutation, isPending } = useMutation({
    mutationKey: [QueryKey.documents, QueryKey.download],
    mutationFn: ({
      params,
    }: {
      format: DownloadAsOption;
      selectedColumns: string[];
      params: Parameters<typeof getAllDocuments>;
    }) => getAllDocuments(...params),
    onSuccess(documents, variables) {
      if (!documents) {
        alert('No data to export');
        return;
      }
      const rows = documents.map((document) => {
        const item: Record<string, string> = {};

        variables.selectedColumns.forEach((selectedColumn) => {
          const column = columns?.find((column) => column.key === selectedColumn);

          if (!column) {
            return;
          }
          item[column.header] = column.textValue(document);
        });

        return item;
      });
      if (rows.length === 0) {
        alert('No data to export');
        return;
      }

      const fileName = `documents_` + moment().format('YYYY_MM_DD_HH-mm-ss');
      if (variables.format === 'CSV') {
        const ws = XLSX.utils.json_to_sheet(rows);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
        const fileBuffer = new Blob([excelBuffer], {
          type: 'application/csv;charset=UTF-8',
        });
        FileSaver.saveAs(fileBuffer, fileName + '.csv');
      }

      if (variables.format === 'Excel') {
        const ws = XLSX.utils.json_to_sheet(rows);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const fileBuffer = new Blob([excelBuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        });
        FileSaver.saveAs(fileBuffer, fileName + '.xlsx');
      }

      onExportFinished?.();
    },
  });

  const handleDownload = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      const filters = convertDocumentQueryRequestToFilter(filterRequest) as Filter[];

      getAllDocumentsMutation({
        format: selectedFormat,
        selectedColumns: selectedColumns,
        params: [
          {
            orgId,
            filters,
            sort: {
              key: filterRequest.order_by ?? 'created_at',
              value: (filterRequest.order_direction as 'asc' | 'desc') ?? 'desc',
            },
          },
        ],
      });

      setIsOpen(false);
      onPrepareExport?.();
    },
    [filterRequest, orgId, getAllDocumentsMutation, selectedColumns, selectedFormat, setIsOpen, onPrepareExport]
  );

  useEffect(() => {
    if (columns) {
      setSelectedColumns(
        columns.filter((column) => columnVisibility?.[column.id] !== false).map((column) => column.key)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(columns), JSON.stringify(columnVisibility)]);

  return (
    orgId && (
      <Dialog open={isOpen} onClose={setIsOpen} size="xl" className="z-50 !p-0">
        <form onSubmit={handleDownload}>
          <DialogTitle className="border-b px-5 py-3">
            <span className="text-lg text-gray-900">Export</span>
            <p className="text-xs text-gray-600">
              All matching results in the table will be downloaded, not limited to the current page
            </p>
          </DialogTitle>
          <DialogBody className="!mt-0 border-b text-sm/6 text-zinc-900 dark:text-white">
            <div className="overflow-x-auto p-5">
              <div>
                <p className="font-bold">Columns</p>
                <CheckboxGroup className="mt-4 grid grid-cols-3 gap-x-4 gap-y-2">
                  {columns?.map((column, index) => (
                    <CheckboxField className="gap-2" key={index}>
                      <Checkbox
                        value={column.key}
                        checked={selectedColumns.includes(column.key)}
                        onChange={(value) => {
                          setSelectedColumns((prev) =>
                            value ? [...prev, column.key] : prev.filter((key) => key !== column.key)
                          );
                        }}
                      />
                      <Label className="text-sm font-medium text-gray-900">{column.header}</Label>
                    </CheckboxField>
                  ))}
                </CheckboxGroup>
              </div>

              <div className="mt-8">
                <p className="font-bold">Download As</p>
                <RadioGroup
                  className="mt-4 flex gap-x-4"
                  value={selectedFormat}
                  onChange={(value) => setSelectedFormat(value as DownloadAsOption)}
                >
                  {downloadAsOptions?.map((option, index) => (
                    <RadioField className="!mt-0 gap-2" key={index}>
                      <Radio value={option} />
                      <Label className="text-sm font-medium text-gray-900">{option}</Label>
                    </RadioField>
                  ))}
                </RadioGroup>
              </div>
            </div>
          </DialogBody>
          <DialogActions className="!mt-0 px-5 py-5">
            <Button plain onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              disabled={isPending}
              type="submit"
              className="data-[disabled]:opacity-100 before:data-[disabled]:bg-gray-400"
            >
              Download
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  );
};
