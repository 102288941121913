import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FetchTasksRequest } from 'shared/firebase/model';
import { listenNewTasksAdded } from 'shared/firebase/query/tasks';
import { newTasksAdded } from 'shared/reduxStore/slices/tasks';
import { AppDispatch } from '../reduxStore/store';

const useDetermineNewTasksAdded = (request: FetchTasksRequest) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!request.org_id) return;
    const unsubscribe = listenNewTasksAdded(request, (data: any) => dispatch(newTasksAdded(data)));
    return () => unsubscribe();
  }, [dispatch, request]);
};

export default useDetermineNewTasksAdded;
