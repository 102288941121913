import { useMutation } from '@tanstack/react-query';
import TaskDiscrepancyPanel from 'containers/Taskdetails/Components/TaskDiscrepancyPanel';
import transformRouteData from 'containers/utils/transformRouteData';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'shared/components/button';
import Drawer from 'shared/components/drawer';
import { QueryKey } from 'shared/constants/queryKeys';
import { listenShipmentDetails } from 'shared/firebase/query/shipments';
import { taskDetailsListener } from 'shared/firebase/query/tasks';
import { RootState } from 'shared/reduxStore/store';
import { taskService } from 'shared/services';

type TaskDiscrepancyDrawerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  taskId: string;
};

export default function TaskDiscrepancyDrawer({ open, setOpen, taskId }: TaskDiscrepancyDrawerProps) {
  const [task, setTask] = useState<any>(null);
  const [loadingTask, setLoadingTask] = useState(true);
  const [shipment, setShipment] = useState<any>(null);
  const [loadingShipment, setLoadingShipment] = useState(false);
  const [selectedValues, setSelectedValues] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const orgId = useSelector((state: RootState) => state.auth.orgId);

  useEffect(() => {
    setSelectedValues({});
  }, [open]);

  const isConfirmDisabled = useMemo(
    () => Object.keys(selectedValues).length === 0 || loadingShipment || task?.status === 'completed' || isLoading,
    [selectedValues, loadingShipment, task, isLoading]
  );

  // Listen for task updates
  useEffect(() => {
    if (taskId) {
      const unsubscribe = taskDetailsListener({ taskId, orgId: orgId, refetch: 0 }, (updatedTask) => {
        setTask(updatedTask);
        setLoadingTask(false);
      });

      return () => unsubscribe(); // Cleanup on unmount
    }
    return () => {}; // Return an empty function if no subscription is created
  }, [taskId, orgId]);

  // Fetch Shipment Details
  useEffect(() => {
    if (task?.data?.shipment_ids?.[0]) {
      const shipmentId = task.data.shipment_ids[0];
      setLoadingShipment(true);
      listenShipmentDetails({ shipmentId, orgId: task.org_id, refetch: 0 }, (shipmentDetails) => {
        setShipment(shipmentDetails);
        setLoadingShipment(false);
      });
    }
  }, [task?.data.shipment_ids, task?.org_id]);

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      setError(null);
      setSuccess(false);

      await taskService.resolveTask({
        id: taskId,
        version: task?.version,
        result: {
          shipment_reference: {
            id: shipment?.id,
            version: shipment?.version,
          },
          shipment_data: selectedValues,
        },
      });

      setSuccess(true);
      setTimeout(() => setOpen(false), 1000); // Close drawer after success
    } catch (err) {
      console.error('Failed to resolve discrepancy task', err);
      setError('Failed to confirm. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const Body =
    loadingTask || loadingShipment ? (
      <></>
    ) : (
      <div className="p-4">
        {task && shipment && (
          <TaskDiscrepancyPanel
            task={task}
            shipment={shipment}
            column={1}
            setSelectedValues={setSelectedValues}
            selectedValues={selectedValues}
          />
        )}
      </div>
    );

  const Footer = (
    <div className="flex w-full flex-col gap-4">
      {error && <p className="text-red-500">{error}</p>}
      {success && <p className="text-green-600">Successfully Saved!</p>}
      <div className="flex w-full flex-row justify-start gap-4">
        <Button
          type="button"
          isLoading={isLoading}
          disabled={isConfirmDisabled}
          onClick={handleConfirm}
        >
          Confirm
        </Button>

        <Button
          outline
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );

  return (
    <Drawer
      className="z-50"
      bodyClassName="px-0 sm:px-0 w-full"
      bodyWrapperClassName="py-0"
      open={open}
      setOpen={setOpen}
      title="Data discrepancy found!"
      subTitle={shipment?.client_shipment_id}
      body={Body}
      footer={Footer}
      panelClassName="max-w-none w-[500px]"
    />
  );
}
