import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import EmptyContent from 'shared/components/empty-content';
import { LoadingIcon } from 'shared/components/loading-icon';
import Spinner from 'shared/components/Spinner/Spinner';
import { listenShipmentDetails } from 'shared/firebase/query/shipments';
import { taskDetailsListener } from 'shared/firebase/query/tasks';
import { taskService } from 'shared/services';
import { handleErrorResponse } from 'shared/services/utils';

import { routesKeys } from '../Shipments/Details/model';
import DetailsHeader from './Components/TaskDetailsHeader';
import TaskDiscrepancyPanel from './Components/TaskDiscrepancyPanel';

const MEASUREMENT_FIELDS = [
  'total_units',
  'total_packages',
  'total_weight',
  'total_volume'
];

const TaskDiscrepancy: React.FC = () => {
  const { taskId } = useParams<{ taskId: string }>();
  const [task, setTask] = useState<any>(null);
  const [loadingTask, setLoadingTask] = useState(true);
  const [shipment, setShipment] = useState<any>(null);
  const [loadingShipment, setLoadingShipment] = useState(false);
  const [selectedValues, setSelectedValues] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState(false); // Track loading state for Confirm button
  const [errorMessage, setErrorMessage] = useState(''); // Track API errors
  const orgId = useSelector((state: any) => state.auth.orgId);
  const [refreshCount, setRefreshCount] = useState(0);
  // Listen for task updates
  useEffect(() => {
    if (taskId) {
      const unsubscribe = taskDetailsListener({ taskId, orgId: orgId, refetch: refreshCount }, (updatedTask) => {
        setTask(updatedTask);
        setLoadingTask(false);
      });

      return () => unsubscribe(); // Cleanup on unmount
    }
    return () => {}; // Return an empty function if no subscription is created
  }, [taskId, refreshCount, orgId]);

  // Fetch Shipment Details
  useEffect(() => {
    if (task?.data?.shipment_ids?.[0]) {
      const shipmentId = task.data.shipment_ids[0];
      setLoadingShipment(true);
      listenShipmentDetails({ shipmentId, orgId: task.org_id, refetch: 0 }, (shipmentDetails) => {
        setShipment(shipmentDetails);
        setLoadingShipment(false);
      });
    }
  }, [task?.data.shipment_ids, task?.org_id]);

  // Determine if Confirm button should be disabled
  const isConfirmDisabled = Object.keys(selectedValues).length === 0 || isLoading || task?.status === 'completed';

  const handleConfirmClick = async () => {
    if (!taskId || isLoading) return; // Prevent empty submission and multiple clicks

    setIsLoading(true); // Start loading

    type TransformedValues = Record<string, any>;

    const transformedValues = Object.entries(selectedValues).reduce<TransformedValues>((acc, [key, value]) => {
      const routeKey = routesKeys.find((route) => route.key === key);
      if (!routeKey) return { ...acc, [key]: value };

      // Handle measurement fields
      if (MEASUREMENT_FIELDS.includes(key) && !isNaN(Number(value))) {
        // Get existing unit from shipment data if available
        const existingField = shipment?.data?.[key];
        console.log(`Processing measurement field ${key}:`, {
          value: value,
          existingField,
          unit: existingField?.unit || 'units'
        });

        acc[key] = {
          value: Number(value),
          unit: existingField?.unit || 'units'
        };
      } else {
        acc[key] = value;
      }

      return acc;
    }, {});

    const payload = {
      id: taskId,
      version: task?.version,
      result: {
        shipment_reference: {
          id: shipment?.id,
          version: shipment?.version,
        },
        shipment_data: {
          ...transformedValues,
          ...(transformedValues['place_of_delivery'] && {
            place_of_delivery: transformedValues['place_of_delivery'].unloc || transformedValues['place_of_delivery'],
          }),
          ...(transformedValues['place_of_receipt'] && {
            place_of_receipt: transformedValues['place_of_receipt'].unloc || transformedValues['place_of_receipt'],
          }),
        },
      },
    };

    try {
      setErrorMessage('');
      await taskService.resolveTask(payload);
      setRefreshCount((prev) => prev + 1); // Refresh the task details
    } catch (err) {
      setErrorMessage(handleErrorResponse(err));
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  if (loadingTask || loadingShipment) {
    return <Spinner />;
  }

  if (!task) return <EmptyContent title="Task not found" className="py-8" />;

  return (
    <div className="flex min-h-screen flex-col">
      <DetailsHeader task={task} />
      <div className="height-screen-minus-navbar container m-6 mx-auto flex flex-col items-center justify-center">
        <div className="h-full w-full overflow-y-auto">
          <TaskDiscrepancyPanel
            task={task}
            shipment={shipment}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
          />
        </div>
      </div>
      <div className="absolute bottom-0 mt-auto w-full border-t border-gray-200 bg-white p-4">
        <div className="flex flex-col items-center justify-end gap-1">
          {errorMessage && <p className="w-full text-right text-red-500">{errorMessage}</p>}
          <div className="flex w-full flex-row items-center justify-end gap-4">
            <button
              disabled={isConfirmDisabled}
              onClick={handleConfirmClick}
              className={twMerge([
                'flex flex-row rounded-lg border px-4 py-2 text-white',
                isConfirmDisabled ? 'cursor-not-allowed bg-gray-400' : 'bg-black hover:bg-gray-700',
              ])}
            >
              {isLoading && <LoadingIcon className="mr-2 h-5 w-5 fill-white text-gray-200" />}
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskDiscrepancy;
