import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

import { cn } from 'shared/utils';
import { Button } from './button';
import { IFilterItem } from './filters-drawer';
import FilterItemCheckBox from './filters-drawer/item-checkbox';
import FilterItemDateDropDown from './filters-drawer/item-date-dropdown';
import FilterItemDateRange from './filters-drawer/item-date-range';
import FilterItemDropdown from './filters-drawer/item-dropdown';
import FilterItemRadioCheckbox from './filters-drawer/item-radio-checkbox';
import FilterItemSearchInput from './filters-drawer/item-search-input';

const MAXIMUM_SELECTED_OPTIONS_TO_DISPLAY = 5;
const SELECTED_OPTIONS_TO_DISPLAY = 3;

interface IActiveFilters {
  filters: any;
  filterItems: IFilterItem[];
  handleApply: (data: any) => void;
  resetFilter: () => void;
}

const ActiveFiltersV2 = ({ filters, filterItems, resetFilter, handleApply }: IActiveFilters) => {
  const [selectedFilters, setSelectedFilter] = useState<any>(filters);

  const activeFilterItems = useMemo(() => {
    return filterItems.reduce((result: IFilterItem[], item: IFilterItem) => {
      if (!item.activeValue) {
        return result;
      }
      if (item.type === 'dateRange' && item.activeValue?.filter((v: any) => v).length === 0) {
        return result;
      }
      if (Array.isArray(item.activeValue) && item.activeValue.length === 0) {
        return result;
      }

      return result.concat({
        ...item,
        value: item.valueComposer ? item.valueComposer(selectedFilters) : selectedFilters[item.key],
        onChange: (value: any) => {
          if (item.onChangeComposer) {
            item.onChangeComposer(setSelectedFilter)(value);
          } else {
            setSelectedFilter((prev: any) => ({
              ...prev,
              [item.key]: value,
            }));
          }
        },
        disabled: item.disabledChecker ? item.disabledChecker(selectedFilters) : false,
      });
    }, []);
  }, [filterItems, selectedFilters]);

  useEffect(() => {
    setSelectedFilter(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters)]);

  if (activeFilterItems.length === 0) return null;

  return (
    <div className="flex flex-wrap gap-x-2 gap-y-2 px-3 pt-3">
      {activeFilterItems.map((item, i) => {
        const isArray = Array.isArray(item.activeValue);

        let displayValue = '';
        if (typeof item.activeValue === 'string') displayValue = item.activeValue;
        if (isArray) {
          if (item.activeValue.length > 0) {
            const labels = item.activeValue.map(
              (v: string) => item.options?.find((o) => o.value === v)?.label || undefined
            );
            if (labels.length > MAXIMUM_SELECTED_OPTIONS_TO_DISPLAY) {
              displayValue =
                labels.slice(0, SELECTED_OPTIONS_TO_DISPLAY).join(', ') +
                '...' +
                `(${labels.length - SELECTED_OPTIONS_TO_DISPLAY} more)`;
            } else {
              displayValue = labels.join(', ');
            }
          }
        }

        return (
          <Popover key={`filter-${i}`} className="relative">
            <PopoverButton className="border-secondary-border flex items-center rounded-lg border bg-white px-4 py-2 text-sm">
              <p>
                {item.type === 'dateRange' ? (
                  <>
                    {item.title}{' '}
                    {item.activeValue[0] === item.activeValue[1] ? (
                      <span>
                        :{' '}
                        <strong className="font-semibold">{moment(item.activeValue[0]).format('MMM DD, YYYY')}</strong>
                      </span>
                    ) : (
                      <span className="capitalize">
                        {item.activeValue[0] ? (
                          <span>
                            From:{' '}
                            <strong className="font-semibold">
                              {moment(item.activeValue[0]).format('MMM DD, YYYY')}
                            </strong>
                          </span>
                        ) : (
                          ''
                        )}
                        {item.activeValue[1] ? (
                          <span>
                            &nbsp;To:{' '}
                            <strong className="font-semibold">
                              {moment(item.activeValue[1]).format('MMM DD, YYYY')}
                            </strong>
                          </span>
                        ) : (
                          ''
                        )}
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    {item.title}:{' '}
                    <strong className="font-semibold capitalize">{displayValue ? displayValue : ''}</strong>
                  </>
                )}
                &nbsp;&nbsp;
              </p>
              <span
                className="hover: cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  item.onClear?.();
                }}
              >
                <XMarkIcon height={15} width={15} />
              </span>
            </PopoverButton>
            <PopoverPanel
              transition
              anchor="bottom"
              className={cn(
                '!max-h-96 min-w-48 !overflow-auto rounded-xl border-gray-200 bg-white text-sm shadow-md transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0'
              )}
            >
              {({ close }) => (
                <>
                  <div className="flex flex-col gap-2 p-3">
                    {item.type === 'checkbox' && <FilterItemCheckBox data={item} />}
                    {item.type === 'inputSearch' && <FilterItemSearchInput data={item} />}
                    {item.type === 'dateRange' && <FilterItemDateRange data={item} />}
                    {item.type === 'dateDropdown' && <FilterItemDateDropDown data={item} />}
                    {item.type === 'radio' && <FilterItemRadioCheckbox data={item} />}
                    {item.type === 'custom' && !!item.component && item.component}
                    {item.type === 'dropdown' && <FilterItemDropdown data={item} hasCombobox={false} />}
                  </div>
                  <Button
                    className="sticky bottom-0 w-full rounded-b-lg rounded-t-none"
                    onClick={() => {
                      handleApply(selectedFilters);
                      close();
                    }}
                  >
                    Apply Filters
                  </Button>
                </>
              )}
            </PopoverPanel>
          </Popover>
        );
      })}
      {activeFilterItems.length > 0 && (
        <button
          onClick={resetFilter}
          className={cn([
            'border-secondary-border flex items-center rounded-lg border bg-white px-4 py-2 text-sm',
            'hover:bg-gray-100',
          ])}
        >
          Reset Filter
        </button>
      )}
    </div>
  );
};
export default ActiveFiltersV2;
