import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  variant?: 'success' | 'error' | 'warning' | 'missing';
};

export default function FileStatusIcon({ variant = 'missing', ...props }: Props) {
  switch (variant) {
    case 'error':
      return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            d="M13.6668 7.00004V6.66671C13.6668 4.15255 13.6668 2.89547 12.8858 2.11442C12.1047 1.33337 10.8476 1.33337 8.33343 1.33337H7.66683C5.15272 1.33337 3.89565 1.33337 3.1146 2.11441C2.33356 2.89545 2.33354 4.15252 2.33352 6.66666L2.3335 9.66671C2.33348 11.8583 2.33347 12.9542 2.93875 13.6917C3.04958 13.8268 3.1734 13.9506 3.30844 14.0614C4.04601 14.6667 5.14182 14.6667 7.33343 14.6667"
            stroke="#E02424"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M5 4.66663H11" stroke="#E02424" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5 8H9" stroke="#E02424" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12.9998 13L11 11M11.0002 13L13 11" stroke="#E02424" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M15.3332 12C15.3332 10.159 13.8408 8.66663 11.9998 8.66663C10.1589 8.66663 8.6665 10.159 8.6665 12C8.6665 13.8409 10.1589 15.3333 11.9998 15.3333C13.8408 15.3333 15.3332 13.8409 15.3332 12Z"
            stroke="#E02424"
          />
        </svg>
      );
    case 'success':
      return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            d="M13.6668 7.00004V6.66671C13.6668 4.15255 13.6668 2.89547 12.8858 2.11442C12.1047 1.33337 10.8476 1.33337 8.33343 1.33337H7.66683C5.15272 1.33337 3.89565 1.33337 3.1146 2.11441C2.33356 2.89545 2.33354 4.15252 2.33352 6.66666L2.3335 9.66671C2.33348 11.8583 2.33347 12.9542 2.93875 13.6917C3.04958 13.8268 3.1734 13.9506 3.30844 14.0614C4.04601 14.6667 5.14182 14.6667 7.33343 14.6667"
            stroke="#057A55"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M5 4.66663H11" stroke="#057A55" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5 8H9" stroke="#057A55" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M15.3332 12.0001C15.3332 10.1591 13.8408 8.66675 11.9998 8.66675C10.1589 8.66675 8.6665 10.1591 8.6665 12.0001C8.6665 13.841 10.1589 15.3334 11.9998 15.3334C13.8408 15.3334 15.3332 13.841 15.3332 12.0001Z"
            stroke="#057A55"
          />
          <path d="M10.6665 12L11.6665 13L13.3332 11" stroke="#057A55" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      );

    case 'warning':
      return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            d="M13.6668 7.00004V6.66671C13.6668 4.15255 13.6668 2.89547 12.8858 2.11442C12.1047 1.33337 10.8476 1.33337 8.33343 1.33337H7.66683C5.15272 1.33337 3.89565 1.33337 3.1146 2.11441C2.33356 2.89545 2.33354 4.15252 2.33352 6.66666L2.3335 9.66671C2.33348 11.8583 2.33347 12.9542 2.93875 13.6917C3.04958 13.8268 3.1734 13.9506 3.30844 14.0614C4.04601 14.6667 5.14182 14.6667 7.33343 14.6667"
            stroke="#C27803"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M5 4.66663H11" stroke="#C27803" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5 8H9" stroke="#C27803" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M15.3332 12.0001C15.3332 10.1591 13.8408 8.66675 11.9998 8.66675C10.1589 8.66675 8.6665 10.1591 8.6665 12.0001C8.6665 13.841 10.1589 15.3334 11.9998 15.3334C13.8408 15.3334 15.3332 13.841 15.3332 12.0001Z"
            stroke="#C27803"
          />
          <path d="M10.6665 12L11.6665 13L13.3332 11" stroke="#C27803" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      );

    case 'missing':
      return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            d="M13.6668 7.00004V6.66671C13.6668 4.15255 13.6668 2.89547 12.8858 2.11442C12.1047 1.33337 10.8476 1.33337 8.33343 1.33337H7.66683C5.15272 1.33337 3.89565 1.33337 3.1146 2.11441C2.33356 2.89545 2.33354 4.15252 2.33352 6.66666L2.3335 9.66671C2.33348 11.8583 2.33347 12.9542 2.93875 13.6917C3.04958 13.8268 3.1734 13.9506 3.30844 14.0614C4.04601 14.6667 5.14182 14.6667 7.33343 14.6667"
            stroke="#9CA3AF"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M5 4.66663H11" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M11.3333 15.3333C13.1743 15.3333 14.6667 13.8409 14.6667 12C14.6667 10.159 13.1743 8.66663 11.3333 8.66663C9.49238 8.66663 8 10.159 8 12C8 13.8409 9.49238 15.3333 11.3333 15.3333Z"
            stroke="#9CA3AF"
          />
          <path
            d="M11.3335 10.6665V11.9998L12.0002 12.6665"
            stroke="#9CA3AF"
            stroke-width="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M5 8H9" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      );

    default:
      return null;
  }
}
