import { AxiosError, AxiosResponse } from 'axios';

export const handleApiResponse = <T>(response: AxiosResponse): T => {
  try {
    if (!response.data) {
      throw new Error('No response data');
    }
    const statusCode = response.status;
    if (statusCode && statusCode !== 200 && statusCode !== 201 && statusCode !== 204) {
      throw new Error(response.data?.message || 'Something went wrong');
    }
    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const handleErrorResponse = (error: unknown) => {
  if (typeof error === 'string') return error;
  if (error instanceof AxiosError) {
    let message = 'Something went wrong';
    if (error.response?.data?.message) {
      message = error.response.data?.message;
    } else if (error.response?.data?.error) {
      message = error.response.data.error;
    } else if (error.response?.data?.errors) {
      message = error.response.data.errors[0]?.message;
    }
    return message;
  }
  if (error instanceof Error) {
    return error.message;
  }
  if ((error as any).message) {
    return (error as any).message;
  }
  return 'Unknown error';
};
