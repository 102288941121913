import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  variant: 'failed' | 'completed' | 'all';
};

export function DocumentStatIcon({ variant, ...props }: Props) {
  switch (variant) {
    case 'failed':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            d="M20.4999 10.5V10C20.4999 6.22876 20.4999 4.34315 19.3284 3.17157C18.1568 2 16.2712 2 12.4999 2H11.5C7.72883 2 5.84323 2 4.67166 3.17156C3.50009 4.34312 3.50007 6.22872 3.50004 9.99993L3.5 14.5C3.49997 17.7874 3.49996 19.4312 4.40788 20.5375C4.57412 20.7401 4.75986 20.9258 4.96242 21.0921C6.06877 22 7.71249 22 10.9999 22"
            stroke="#141B34"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M7.5 7H16.5" stroke="#141B34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7.5 12H13.5" stroke="#141B34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M19.4997 19.5L16.5 16.5M16.5003 19.5L19.5 16.5"
            stroke="#141B34"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23 18C23 15.2386 20.7614 13 18 13C15.2386 13 13 15.2386 13 18C13 20.7614 15.2386 23 18 23C20.7614 23 23 20.7614 23 18Z"
            stroke="#141B34"
            strokeWidth="1.5"
          />
        </svg>
      );
    case 'completed':
      return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            d="M20.8334 10.5V10C20.8334 6.22876 20.8334 4.34315 19.6619 3.17157C18.4903 2 16.6047 2 12.8334 2H11.8335C8.06233 2 6.17673 2 5.00516 3.17156C3.83359 4.34312 3.83357 6.22872 3.83354 9.99993L3.8335 14.5C3.83347 17.7874 3.83346 19.4312 4.74138 20.5375C4.90762 20.7401 5.09336 20.9258 5.29592 21.0921C6.40227 22 8.04599 22 11.3334 22"
            stroke="#141B34"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M7.8335 7H16.8335" stroke="#141B34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M7.8335 12H13.8335"
            stroke="#141B34"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.3335 18.0001C23.3335 15.2387 21.0949 13.0001 18.3335 13.0001C15.5721 13.0001 13.3335 15.2387 13.3335 18.0001C13.3335 20.7615 15.5721 23.0001 18.3335 23.0001C21.0949 23.0001 23.3335 20.7615 23.3335 18.0001Z"
            stroke="#141B34"
            strokeWidth="1.5"
          />
          <path
            d="M16.3335 18L17.8335 19.5L20.3335 16.5"
            stroke="#141B34"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'all':
      return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            d="M2.6665 13C2.6665 8.28596 2.6665 5.92894 3.9113 4.46446C5.1561 3 7.15957 3 11.1665 3C15.1734 3 17.1769 3 18.4217 4.46446C19.6665 5.92894 19.6665 8.28596 19.6665 13C19.6665 17.714 19.6665 20.0711 18.4217 21.5356C17.1769 23 15.1734 23 11.1665 23C7.15957 23 5.1561 23 3.9113 21.5356C2.6665 20.0711 2.6665 17.714 2.6665 13Z"
            fill="white"
            stroke="#141B34"
            strokeWidth="1.5"
          />
          <path
            d="M5.6665 11C5.6665 6.28596 5.6665 3.92894 6.9113 2.46446C8.1561 1 10.1596 1 14.1665 1C18.1734 1 20.1769 1 21.4217 2.46446C22.6665 3.92894 22.6665 6.28596 22.6665 11C22.6665 15.714 22.6665 18.0711 21.4217 19.5356C20.1769 21 18.1734 21 14.1665 21C10.1596 21 8.1561 21 6.9113 19.5356C5.6665 18.0711 5.6665 15.714 5.6665 11Z"
            fill="white"
            stroke="#141B34"
            strokeWidth="1.5"
          />
          <path d="M9.6665 5.5H18.6665H9.6665Z" fill="white" />
          <path
            d="M9.6665 5.5H18.6665"
            stroke="#141B34"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M9.6665 10.5H15.6665H9.6665Z" fill="white" />
          <path
            d="M9.6665 10.5H15.6665"
            stroke="#141B34"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    default:
      return null;
  }
}
