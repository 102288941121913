import React from 'react';

import { EProcessingStatus } from 'shared/constants/enums';
import { DocumentProcessingStatusLabel } from 'shared/constants/labels';
import { cn } from 'shared/utils';

type ProcessingStatusProps = {
  text: EProcessingStatus;
  className?: string;
  variant?: 'default' | 'inline';
};

const ProcessingStatus = ({ text, className, variant = 'inline' }: ProcessingStatusProps) => {
  const isDefault = variant === 'default';

  const isSuccess = text === EProcessingStatus.success;
  const isFailed = text === EProcessingStatus.failed;
  const isUnreceived = text === EProcessingStatus.unreceived;

  return (
    <span
      className={cn(
        'font-medium',
        {
          'text-green-600': isSuccess,
          'text-red-600': isFailed,
          'text-zinc-700': isUnreceived,
        },
        isDefault && {
          'w-fit rounded-md px-[10px] py-[2px]': true,
          'bg-green-100 text-green-800': isSuccess,
          'bg-red-100 text-red-800': isFailed,
          'bg-zinc-100 text-zinc-800': isUnreceived,
        },
        className
      )}
    >
      {DocumentProcessingStatusLabel[text] || text}
    </span>
  );
};

export default ProcessingStatus;
