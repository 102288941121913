import { TaskType } from 'shared/constants/enums';

export const taskDueDateOptions = 3;

export const getDueDateColor = (dueDate: Date) => {
  const now = new Date();
  const due = new Date(dueDate);
  const diff = due.getTime() - now.getTime();
  const diffInHours = diff / (1000 * 60 * 60);
  const diffInDays = diffInHours / 24;
  if (diffInHours < 0) {
    return 'text-red-700';
  } else if (diffInDays < taskDueDateOptions) {
    return 'text-yellow-500';
  } else {
    return 'tex-black';
  }
};

export const getDueDateText = (dueDate: Date, status: string) => {
  if (status === 'completed') return 'Completed';
  const now = new Date();
  const due = new Date(dueDate);
  const diff = due.getTime() - now.getTime();
  const diffInHours = diff / (1000 * 60 * 60);
  const diffInDays = diffInHours / 24;
  if (diffInHours < 0) {
    return 'Overdue';
  } else if (diffInDays < taskDueDateOptions) {
    return 'Due soon';
  } else {
    return 'Upcoming';
  }
};

export const getTaskStatus = (status: string) => {
  switch (status) {
    case 'assigned':
      return 'Incomplete';
    case 'in_progress':
      return 'Inprogress';
    case 'completed':
      return 'Completed';
    case 'error':
      return 'Error';
    case 'pending_action':
      return 'Pending action';
    default:
      return 'Unknown';
  }
};

export const getTaskStatusColor = (status: string) => {
  switch (status) {
    case 'assigned':
      return 'text-gray-900';
    case 'in_progress':
      return 'text-yellow-500';
    case 'completed':
      return 'text-gray-400';
    case 'error':
      return 'text-red-700';
    case 'pending_action':
      return 'text-yellow-500';
    default:
      return 'text-black';
  }
};

export const getTaskLinkPrefix = (type: TaskType) => {
  switch (type) {
    case TaskType.received_corrupt_document:
      return '/task/corrupt-document';
    case TaskType.unassigned_shipment:
      return '/task/unassigned';
    case TaskType.received_duplicate_document:
      return '/task/duplicate';
    case TaskType.shipment_data_discrepancy:
      return '/task/discrepancy';
    case TaskType.unregistered_party_address:
      return '/task/missing-org-data';
    default:
      return '';
  }
};
