import { TaskType } from './enums';
import { TaskTypeLabel } from './labels';

export const TASK_URGENCY_FILTER_OPTIONS = [
  { label: 'Overdue', value: 'overdue' },
  { label: 'Due soon', value: 'due_soon' },
  { label: 'Upcoming', value: 'upcoming' },
];

export const TASK_TYPE_FILTER_OPTIONS = Object.keys(TaskType).map((key) => ({
  value: key,
  label: TaskTypeLabel[key as TaskType],
}));

export const PROCESSING_STATUS_OPTIONS = [
  {
    label: 'Unreceived',
    value: 'unreceived',
  },
  {
    label: 'Failed processing',
    value: 'failed',
  },
  {
    label: 'Successfully processed',
    value: 'success',
  },
];

export const VALIDATION_STATUS_OPTIONS = [
  {
    label: 'Unvalidated',
    value: 'unvalidated',
  },
  {
    label: 'Validating',
    value: 'partial_ok',
  },
  {
    label: 'Discrepancy',
    value: 'discrepancy',
  },
  {
    label: 'No discrepancy',
    value: 'all_ok',
  },
];

export const MODE_OPTIONS = [
  {
    label: 'FCL',
    value: 'fcl',
  },
  {
    label: 'LCL',
    value: 'lcl',
  },
  {
    label: 'AIR',
    value: 'air',
  },
  {
    label: 'FTL',
    value: 'ftl',
  },
  {
    label: 'LTL',
    value: 'ltl',
  },
];
