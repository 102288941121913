import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  variant: 'hasDiscrepancies' | 'missingDocuments' | 'inprogress' | 'completed' | 'all';
};

export function ShipmentStatIcon({ variant, ...props }: Props) {
  switch (variant) {
    case 'hasDiscrepancies':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            d="M20 12.1818V7.81818C20 6.12494 20 5.27832 19.732 4.60214C19.3012 3.5151 18.3902 2.65765 17.2352 2.2522C16.5168 2 15.6173 2 13.8182 2C10.6698 2 9.09563 2 7.83836 2.44135C5.81714 3.15089 4.22281 4.65142 3.46894 6.55375C3 7.73706 3 9.21865 3 12.1818V14.7273C3 17.7966 3 19.3313 3.8477 20.3971C4.09058 20.7025 4.37862 20.9736 4.70307 21.2022C5.74797 21.9384 7.21706 21.9952 10 21.9996"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3 12C3 10.1591 4.49238 8.66667 6.33333 8.66667C6.99912 8.66667 7.78404 8.78333 8.43137 8.60988C9.00652 8.45576 9.45576 8.00652 9.60988 7.43136C9.78333 6.78404 9.66667 5.99912 9.66667 5.33333C9.66667 3.49238 11.1591 2 13 2"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.4997 21.9165C17.4912 21.9165 19.9163 19.4914 19.9163 16.4999C19.9163 13.5083 17.4912 11.0832 14.4997 11.0832C11.5081 11.0832 9.08301 13.5083 9.08301 16.4999C9.08301 19.4914 11.5081 21.9165 14.4997 21.9165Z"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M14.5 14.6043V16.771"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.5 19.3955H14.5052"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'missingDocuments':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            d="M20 12.1818V7.81818C20 6.12494 20 5.27832 19.732 4.60214C19.3012 3.5151 18.3902 2.65765 17.2352 2.2522C16.5168 2 15.6173 2 13.8182 2C10.6698 2 9.09563 2 7.83836 2.44135C5.81714 3.15089 4.22281 4.65142 3.46894 6.55375C3 7.73706 3 9.21865 3 12.1818V14.7273C3 17.7966 3 19.3313 3.8477 20.3971C4.09058 20.7025 4.37862 20.9736 4.70307 21.2022C5.74797 21.9384 7.21706 21.9952 10 21.9996"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3 12C3 10.1591 4.49238 8.66667 6.33333 8.66667C6.99912 8.66667 7.78404 8.78333 8.43137 8.60988C9.00652 8.45576 9.45576 8.00652 9.60988 7.43136C9.78333 6.78404 9.66667 5.99912 9.66667 5.33333C9.66667 3.49238 11.1591 2 13 2"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 23C18.7614 23 21 20.7614 21 18C21 15.2386 18.7614 13 16 13C13.2386 13 11 15.2386 11 18C11 20.7614 13.2386 23 16 23Z"
            stroke="#111928"
            strokeWidth="1.5"
          />
          <path
            d="M16 15.9998V17.9998L17 18.9998"
            stroke="#111928"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'inprogress':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            d="M20 12.1818V7.81818C20 6.12494 20 5.27832 19.732 4.60214C19.3012 3.5151 18.3902 2.65765 17.2352 2.2522C16.5168 2 15.6173 2 13.8182 2C10.6698 2 9.09563 2 7.83836 2.44135C5.81714 3.15089 4.22281 4.65142 3.46894 6.55375C3 7.73706 3 9.21865 3 12.1818V14.7273C3 17.7966 3 19.3313 3.8477 20.3971C4.09058 20.7025 4.37862 20.9736 4.70307 21.2022C5.74797 21.9384 7.21706 21.9952 10 21.9996"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3 12C3 10.1591 4.49238 8.66667 6.33333 8.66667C6.99912 8.66667 7.78404 8.78333 8.43137 8.60988C9.00652 8.45576 9.45576 8.00652 9.60988 7.43136C9.78333 6.78404 9.66667 5.99912 9.66667 5.33333C9.66667 3.49238 11.1591 2 13 2"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 17L13.0228 17.5944C13.5374 15.7277 15.5114 14.6199 17.4318 15.1201C18.4149 15.3761 19.1934 16.0039 19.6501 16.806M21 20L19.9774 19.4056C19.4628 21.2723 17.4888 22.3801 15.5684 21.8799C14.6081 21.6298 13.8431 21.0251 13.3824 20.2496"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'completed':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            d="M20 12.1818V7.81818C20 6.12494 20 5.27832 19.732 4.60214C19.3012 3.5151 18.3902 2.65765 17.2352 2.2522C16.5168 2 15.6173 2 13.8182 2C10.6698 2 9.09563 2 7.83836 2.44135C5.81714 3.15089 4.22281 4.65142 3.46894 6.55375C3 7.73706 3 9.21865 3 12.1818V14.7273C3 17.7966 3 19.3313 3.8477 20.3971C4.09058 20.7025 4.37862 20.9736 4.70307 21.2022C5.74797 21.9384 7.21706 21.9952 10 21.9996"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3 12C3 10.1591 4.49238 8.66667 6.33333 8.66667C6.99912 8.66667 7.78404 8.78333 8.43137 8.60988C9.00652 8.45576 9.45576 8.00652 9.60988 7.43136C9.78333 6.78404 9.66667 5.99912 9.66667 5.33333C9.66667 3.49238 11.1591 2 13 2"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.9805 18.0156C13.9805 18.0156 14.4805 18.5156 14.9805 19.5156C14.9805 19.5156 16.5687 17.0156 17.9805 16.5156"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.9998 18.0156C20.9998 20.777 18.7591 23.0157 15.995 23.0157C13.2309 23.0157 10.9902 20.777 10.9902 18.0156C10.9902 15.2542 13.2309 13.0156 15.995 13.0156C18.7591 13.0156 20.9998 15.2542 20.9998 18.0156Z"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      );

    case 'all':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            d="M3.99207 12.001C3.99573 6.81511 3.68025 4.10995 5 2.49982C6.31976 0.889691 8.75936 1.00338 13.0039 1.00638C17.2485 1.00937 19.3708 1.01087 20.6883 2.62285C22.0058 4.23485 22.0039 6.82781 22.0003 12.0137C21.9966 17.1996 21.9948 19.7926 20.6751 21.4028C19.3553 23.0129 17.2329 23.0114 12.9884 23.0084C8.74384 23.0054 6.62155 23.0039 5.30406 21.3919C3.98658 19.7799 3.98841 17.1869 3.99207 12.001Z"
            fill="white"
            stroke="#141B34"
            strokeWidth="1.5"
          />
          <path
            d="M20.3414 12.307C20.3313 14.0056 18.8053 15.3771 16.9282 15.3721C16.7368 15.3716 16.5358 15.3625 16.331 15.3533C15.808 15.3297 15.2603 15.3049 14.7848 15.419C14.1969 15.56 13.7369 15.9743 13.578 16.506C13.4495 16.936 13.4739 17.4321 13.4972 17.9059C13.5063 18.0914 13.5153 18.2734 13.5148 18.4467C13.5104 20.1323 12.0133 21.4981 10.1592 21.521L10.1592 21.0002C6.78834 20.9912 5.04508 21.5075 4.00056 20.5064C2.95603 19.5054 2.9603 17.8987 2.96883 14.6854L2.98177 9.81646C2.99031 6.60315 2.99458 4.99653 4.04441 4.00105C5.09424 3.00556 6.62912 1.49123 10 1.50018L13.578 1.50018C16.9489 1.50914 17.4555 2.99999 18.5 4.00105C19.5446 5.0021 19.0085 6.78687 19 10.0002L20.3414 12.307Z"
            fill="white"
          />
          <path
            d="M19 11.1817V6.81806C19 5.12482 19 4.2782 18.732 3.60202C18.3012 2.51498 17.3902 1.65753 16.2352 1.25208C15.5168 0.99988 14.6173 0.99988 12.8182 0.99988C9.66981 0.99988 8.09563 0.999881 6.83836 1.44123C4.81714 2.15076 3.22281 3.6513 2.46894 5.55363C2 6.73694 2 8.21853 2 11.1817V13.7272C2 16.7965 2 18.3312 2.8477 19.397C3.09058 19.7024 3.37862 19.9735 3.70307 20.202C4.74797 20.9382 6.21706 20.9951 9 20.9995"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 11.1817V6.81806C19 5.12482 19 4.2782 18.732 3.60202C18.3012 2.51498 17.3902 1.65753 16.2352 1.25208C15.5168 0.99988 14.6173 0.99988 12.8182 0.99988C9.66981 0.99988 8.09563 0.999881 6.83836 1.44123C4.81714 2.15076 3.22281 3.6513 2.46894 5.55363C2 6.73694 2 8.21853 2 11.1817V13.7272C2 16.7965 2 18.3312 2.8477 19.397C3.09058 19.7024 3.37862 19.9735 3.70307 20.202C4.74797 20.9382 6.21706 20.9951 9 20.9995"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M19 10V18C19 19.6569 17.6569 21 16 21H8" stroke="#111928" strokeWidth="1.5" strokeLinejoin="round" />
          <path
            d="M2 11C2 9.15905 3.49238 7.66667 5.33333 7.66667C5.99912 7.66667 6.78404 7.78333 7.43137 7.60988C8.00652 7.45576 8.45576 7.00652 8.60988 6.43136C8.78333 5.78404 8.66667 4.99912 8.66667 4.33333C8.66667 2.49238 10.1591 1 12 1"
            stroke="#111928"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    default:
      return null;
  }
}
