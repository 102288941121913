import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import React, { useState } from 'react';

import { DEFAULT_HANDLE_CLASSES } from 'models/ShipmentDetails/constants';
import { EShipmentRouteHandleId, EShipmentRouteVariant } from 'models/ShipmentDetails/enums';
import { getHandleStyles } from 'models/ShipmentDetails/helpers';
import { TOceanContainer } from 'shared/constants/types';
import { cn } from 'shared/utils';
import ContainerBlock, { DEFAULT_CONTAINER_STATUS_LIST, TContainerStatusList } from './Blocks/ContainerBlock';
import CustomsBlock from './Blocks/CustomsBlock';
import HeadBlock from './Blocks/HeadBlock';
import TimeBlock from './Blocks/TimeBlock';

export type TPortOfDischargeNode = Node<{
  label: string;
  location?: string;
  customs?: boolean;
  variant: EShipmentRouteVariant;
  containerStatusList?: TContainerStatusList;
  containers: TOceanContainer[];
  onOpenContainerList: () => void;
  actualTime?: number;
  estimatedTime?: number;
}>;

const PortOfDischargeNode = (props: NodeProps<TPortOfDischargeNode>) => {
  const {
    location,
    label,
    customs,
    estimatedTime,
    actualTime,
    variant,
    containers,
    onOpenContainerList,
    containerStatusList = DEFAULT_CONTAINER_STATUS_LIST,
  } = props.data || {};
  const [isExpanded, setIsExpanded] = useState(true);

  const isInactive = variant === EShipmentRouteVariant.inactive;
  const isActive = variant === EShipmentRouteVariant.active;

  return (
    <>
      <div
        className={cn(
          'leading-1 flex !w-[230px] flex-col gap-2 rounded-md border border-gray-300 bg-white px-3 py-4 text-[10px]',
          isActive && 'border-indigo-300 bg-indigo-100',
          isInactive && 'border-gray-200'
        )}
      >
        <HeadBlock
          subLabel={location}
          label={label}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          variant={variant}
          type="port"
        />

        {isExpanded && (
          <>
            {customs && <CustomsBlock variant={variant} />}
            {
              <ContainerBlock
                variant={variant}
                containers={containers}
                onOpenContainerList={onOpenContainerList}
                containerStatusList={containerStatusList}
                showStatus
              />
            }
            {estimatedTime && <TimeBlock variant="delivery" vehicle="ship" estimatedTime={estimatedTime} />}
            {actualTime && <TimeBlock variant="delivery" vehicle="ship" isCompleted actualTime={actualTime} />}{' '}
          </>
        )}
      </div>
      <Handle
        id={EShipmentRouteHandleId.top}
        type="target"
        position={Position.Top}
        className={DEFAULT_HANDLE_CLASSES}
      />
      <Handle
        id={EShipmentRouteHandleId.left}
        type="source"
        position={Position.Left}
        className={DEFAULT_HANDLE_CLASSES}
      />
      <Handle
        id={EShipmentRouteHandleId.left5_6}
        type="source"
        position={Position.Left}
        className={DEFAULT_HANDLE_CLASSES}
        style={getHandleStyles(EShipmentRouteHandleId.left5_6)}
      />
      <Handle
        id={EShipmentRouteHandleId.right}
        type="source"
        position={Position.Right}
        className={DEFAULT_HANDLE_CLASSES}
      />
      <Handle
        id={EShipmentRouteHandleId.bottom}
        type="source"
        position={Position.Bottom}
        className={DEFAULT_HANDLE_CLASSES}
      />
    </>
  );
};

export default PortOfDischargeNode;
