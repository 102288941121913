import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  currentPageNumber: 0,
  totalPages: 0,
  tasks: [],
  firstToken: '',
  lastToken: '',
  isLoading: false,
  totalData: 0,
  error: null,
  hasNewTask: false,
  lastTimeLoaded: Date.now(),
  lastTimeLoadedTemp: Date.now(),
  lastTaskCreatedTime: Date.now(),
  newTaskIds: [],
  loadedTimeCounter: 0,
  stats: {
    pending: 0,
    inProgress: 0,
    completed: 0,
    overdue: 0,
    loading: true,
  },
};

const tasks = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    updateLoader(state) {
      state.isLoading = true;
    },
    updateTasks(state, action) {
      state.tasks = action.payload.tasks;
      state.totalPages = action.payload.totalPages;
      state.firstToken = action.payload.firstToken;
      state.lastToken = action.payload.lastToken;
      state.totalData = action.payload.totalData;
      state.error = null;
      state.isLoading = false;
    },
    updateStatsLoading(state) {
      state.stats.loading = true;
    },
    updateTaskStats(state, action) {
      state.stats = {
        ...action.payload,
        loading: false,
      };
    },
    newTasksAdded(state, action) {
      if (!state.newTaskIds.includes(action.payload.id)) {
        state.hasNewTask = true;
        state.lastTaskCreatedTime = new Date(action.payload.created_at.seconds * 1000).getTime();
        state.newTaskIds.push(action.payload.id);
      }
    },
    loadNewTasks(state) {
      state.lastTimeLoaded = state.lastTimeLoadedTemp;
    },
    loadedNewTasks(state) {
      state.hasNewTask = false;
      state.lastTimeLoadedTemp = state.lastTaskCreatedTime;
    },
    firstTimeLoadedTasks(state) {
      if (state.loadedTimeCounter === 0) {
        state.hasNewTask = false;
        state.loadedTimeCounter = 1;
        state.lastTimeLoadedTemp = state.lastTaskCreatedTime;
      }
    },
  },
});

export const {
  updateTasks,
  updateLoader,
  updateStatsLoading,
  updateTaskStats,
  newTasksAdded,
  loadNewTasks,
  loadedNewTasks,
  firstTimeLoadedTasks,
} = tasks.actions;
export default tasks.reducer;
