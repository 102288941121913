import React from 'react';

type HeaderTaskIconProps = {
  variant?: 'default' | 'active';
};

const HeaderTaskIcon: React.FC<HeaderTaskIconProps> = ({ variant = 'default' }) => {
  if (variant === 'active') {
    return (
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.66669 9.36842C4.66669 5.89491 4.66669 4.15816 5.69182 3.07908C6.71694 2 8.36686 2 11.6667 2H14.6667C17.9665 2 19.6164 2 20.6416 3.07908C21.6667 4.15816 21.6667 5.89491 21.6667 9.36842V14.6316C21.6667 18.1051 21.6667 19.8418 20.6416 20.9209C19.6164 22 17.9665 22 14.6667 22H11.6667C8.36686 22 6.71694 22 5.69182 20.9209C4.66669 19.8418 4.66669 18.1051 4.66669 14.6316V9.36842Z"
          fill="#E5E7EB"
          stroke="#111928"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.16669 2L9.24889 2.4932C9.44843 3.69044 9.5482 4.28906 9.96782 4.64453C10.3874 5 10.9943 5 12.2081 5H14.1253C15.3391 5 15.946 5 16.3656 4.64453C16.7852 4.28906 16.885 3.69044 17.0845 2.4932L17.1667 2"
          fill="black"
        />
        <path
          d="M9.16669 2L9.24889 2.4932C9.44843 3.69044 9.5482 4.28906 9.96782 4.64453C10.3874 5 10.9943 5 12.2081 5H14.1253C15.3391 5 15.946 5 16.3656 4.64453C16.7852 4.28906 16.885 3.69044 17.0845 2.4932L17.1667 2"
          stroke="#111928"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M9.16669 16H13.1667H9.16669ZM9.16669 11H17.1667H9.16669Z" fill="#111928" />
        <path d="M9.16669 16H13.1667M9.16669 11H17.1667" stroke="#111928" strokeWidth="1.5" strokeLinecap="round" />
      </svg>
    );
  }

  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66669 9.36842C4.66669 5.89491 4.66669 4.15816 5.69182 3.07908C6.71694 2 8.36686 2 11.6667 2H14.6667C17.9665 2 19.6164 2 20.6416 3.07908C21.6667 4.15816 21.6667 5.89491 21.6667 9.36842V14.6316C21.6667 18.1051 21.6667 19.8418 20.6416 20.9209C19.6164 22 17.9665 22 14.6667 22H11.6667C8.36686 22 6.71694 22 5.69182 20.9209C4.66669 19.8418 4.66669 18.1051 4.66669 14.6316V9.36842Z"
        fill="white"
        stroke="#9CA3AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16669 2L9.24889 2.4932C9.44843 3.69044 9.5482 4.28906 9.96782 4.64453C10.3874 5 10.9943 5 12.2081 5H14.1253C15.3391 5 15.946 5 16.3656 4.64453C16.7852 4.28906 16.885 3.69044 17.0845 2.4932L17.1667 2"
        stroke="#9CA3AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.16669 16H13.1667H9.16669ZM9.16669 11H17.1667H9.16669Z" fill="#9CA3AF" />
      <path d="M9.16669 16H13.1667M9.16669 11H17.1667" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
export default HeaderTaskIcon;
