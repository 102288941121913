import { IValidation } from 'shared/constants/enums';

export interface ShipmentDetailsState {
  involvedParties: Array<InvolvedParty>;
  containers: Array<Container>;
  routes: Array<Routes>;
}

export interface Routes {
  fieldName: string;
  value: string;
  valueToCompareWithValidation: string;
  validation: IValidation;
  accessorKey?: string;
}

export interface InvolvedParty {
  partyName: string;
  name: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  nameKey: string;
  emailKey: string;
  addressLine1Key: string;
  addressLine2Key: string;
  cityKey: string;
  stateKey: string;
  countryKey: string;
  postalCodeKey: string;
}

export interface Container {
  containerNumber: string;
  type: string;
  cargoDescription: string;
  sealNumber?: string;
  packageCount?: string;
  packageUom?: string;
  weight?: string;
  volume?: string;
  palletCount?: string;
}

export const routesKeys: Array<{
  key: string;
  accesorKey?: string;
  appendKey?: string;
  isTimestamp?: boolean;
  fallBackValidationKey?: string;
  fallBackValidationKey2?: string;
}> = [
  {
    key: 'load_type',
    accesorKey: '',
  },
  {
    key: 'freight_terms',
  },
  {
    key: 'freight_service',
  },
  {
    key: 'incoterm',
    accesorKey: '',
  },
  {
    key: 'house_bill_of_lading_release_type',
    accesorKey: '',
  },
  {
    key: 'number_of_origin_bill_of_lading',
  },
  {
    key: 'master_bill_of_lading_number',
    accesorKey: '',
  },
  {
    key: 'house_bill_of_lading_number',
    accesorKey: '',
  },
  {
    key: 'automated_manifest_system_number',
    accesorKey: '',
  },
  {
    key: 'vessel_name',
    accesorKey: '',
    fallBackValidationKey: 'vessel',
  },
  {
    key: 'vessel_voyage_number',
    accesorKey: '',
    fallBackValidationKey: 'voyage_number',
    fallBackValidationKey2: 'voyage',
  },
  {
    key: 'port_of_loading',
    accesorKey: 'unloc',
  },
  {
    key: 'port_of_discharge',
    accesorKey: 'unloc',
  },
  {
    key: 'place_of_receipt',
    accesorKey: 'unloc',
  },
  {
    key: 'place_of_delivery',
    accesorKey: 'unloc',
  },
  {
    key: 'shipped_on_board_date',
    accesorKey: 'seconds',
    isTimestamp: true,
  },
  {
    key: 'estimated_time_of_departure',
    accesorKey: 'seconds',
    isTimestamp: true,
  },
  {
    key: 'estimated_time_of_arrival',
    accesorKey: 'seconds',
    isTimestamp: true,
  },
  {
    key: 'total_containers',
    accesorKey: 'value',
    appendKey: 'unit',
  },
  {
    key: 'total_weight',
    accesorKey: 'value',
    appendKey: 'unit',
  },
  {
    key: 'total_volume',
    accesorKey: 'value',
    appendKey: 'unit',
  },
  {
    key: 'total_packages',
    accesorKey: 'value',
    appendKey: 'unit',
  },
  {
    key: 'total_pallets',
    accesorKey: 'value',
    appendKey: 'unit',
  },
  // {
  //   key: 'ocean_carrier',
  // },
  {
    key: 'total_units',
    accesorKey: 'value',
    appendKey: 'unit',
  },
  // {
  //   key: 'total_value',
  //   accesorKey: 'amount',
  //   appendKey: 'currency_code',
  // },
];

export enum ShipmentDetailFieldLabel {
  'freight_terms' = 'Freight terms',
  'freight_service' = 'Freight service',
  'automated_manifest_system_number' = 'AMS #',
  'estimated_time_of_arrival' = 'ETA',
  'estimated_time_of_departure' = 'ETD',
  'house_bill_of_lading_number' = 'HBL #',
  'house_bill_of_lading_release_type' = 'HBL release type',
  'incoterm' = 'Incoterm',
  'load_type' = 'Mode',
  'master_bill_of_lading_number' = 'MBL #',
  'ocean_carrier' = 'Carrier',
  'number_of_origin_bill_of_lading' = 'Number of origin BOL',
  'port_of_discharge' = 'Port of discharge',
  'port_of_loading' = 'Port of loading',
  'place_of_receipt' = 'Place of receipt',
  'place_of_delivery' = 'Place of delivery',
  'total_packages' = 'Total packages',
  'total_containers' = 'Total containers',
  'total_volume' = 'Total volume',
  'total_weight' = 'Total weight',
  'total_pallets' = 'Total pallets',
  'vessel' = 'Vessel',
  'vessel_name' = 'Vessel',
  'voyage' = 'Voyage',
  'vessel_voyage_number' = 'Voyage',
  'voyage_number' = 'Voyage',
  'total_value' = 'Value',
  'total_units' = 'Units',
  'shipped_on_board_date' = 'Shipped on board date',
}
