import React from 'react';

type HeaderDocumentIconProps = {
  variant?: 'default' | 'active';
};

const HeaderDocumentIcon: React.FC<HeaderDocumentIconProps> = ({ variant = 'default' }) => {
  if (variant === 'active') {
    return (
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.66669 12C4.66669 7.28596 4.66669 4.92894 5.91148 3.46446C7.15628 2 9.15975 2 13.1667 2C17.1736 2 19.1771 2 20.4219 3.46446C21.6667 4.92894 21.6667 7.28596 21.6667 12C21.6667 16.714 21.6667 19.0711 20.4219 20.5356C19.1771 22 17.1736 22 13.1667 22C9.15975 22 7.15628 22 5.91148 20.5356C4.66669 19.0711 4.66669 16.714 4.66669 12Z"
          fill="#E5E7EB"
          stroke="#111928"
          strokeWidth="1.5"
        />
        <path d="M8.66669 6.5H17.6667H8.66669Z" fill="white" />
        <path
          d="M8.66669 6.5H17.6667"
          stroke="#111928"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M8.66669 11.5H14.6667H8.66669Z" fill="white" />
        <path
          d="M8.66669 11.5H14.6667"
          stroke="#111928"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66669 12C4.66669 7.28596 4.66669 4.92894 5.91148 3.46446C7.15628 2 9.15975 2 13.1667 2C17.1736 2 19.1771 2 20.4219 3.46446C21.6667 4.92894 21.6667 7.28596 21.6667 12C21.6667 16.714 21.6667 19.0711 20.4219 20.5356C19.1771 22 17.1736 22 13.1667 22C9.15975 22 7.15628 22 5.91148 20.5356C4.66669 19.0711 4.66669 16.714 4.66669 12Z"
        fill="white"
        stroke="#9CA3AF"
        strokeWidth="1.5"
      />
      <path d="M8.66669 6.5H17.6667H8.66669Z" fill="white" />
      <path d="M8.66669 6.5H17.6667" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.66669 11.5H14.6667H8.66669Z" fill="white" />
      <path d="M8.66669 11.5H14.6667" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
export default HeaderDocumentIcon;
