import { useMutation } from '@tanstack/react-query';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ActiveFiltersV2 from 'shared/components/active-filters-v2';
import { Button } from 'shared/components/button';
import DataTableV2 from 'shared/components/data-table-v2';
import DocumentChip from 'shared/components/DocumentChip';
import ExportPreparedAlert from 'shared/components/ExportPreparedAlert';
import { IFilterItem } from 'shared/components/filters-drawer';
import FilterItemDropdown from 'shared/components/filters-drawer/item-dropdown';
import { disabledStatus } from 'shared/components/models/documentTypeMap';
import { shipmentSortingColIdMap } from 'shared/components/models/sortingMap';
import StatCard from 'shared/components/StatCard';
import Status from 'shared/components/status';
import { EDocumentType, EValidationStatus } from 'shared/constants/enums';
import { SortMode } from 'shared/constants/filters';
import { MODE_OPTIONS, VALIDATION_STATUS_OPTIONS } from 'shared/constants/options';
import { QueryKey } from 'shared/constants/queryKeys';
import { TDocumentType } from 'shared/constants/types';
import { Filter, PaginatedQueryRequest } from 'shared/firebase/model';
import { findTaskDiscrepancy } from 'shared/firebase/query/tasks';
import { getStorage, useLocalStorage } from 'shared/hooks/useLocalStorage';
import useShipments from 'shared/hooks/useShipments';
import FileSaveIcon from 'shared/icons/FileSave';
import { ShipmentStatIcon } from 'shared/icons/ShipmentStatIcon';
import { RootState } from 'shared/reduxStore/store';
import { DOCUMENT_TYPES_OPTIONS } from 'shared/services';
import { convertMillisecondToLocaleString } from 'shared/utils/date';
import { sortDocumentByTypeCB } from 'shared/utils/documents';
import AddressCell from '../models/Shipments/components/Cells/AddressCell';
import ContainerCell from '../models/Shipments/components/Cells/ContainerCell';
import PODCell from '../models/Shipments/components/Cells/PODCell';
import POLCell from '../models/Shipments/components/Cells/POLCell';
import DocumentDrawer from '../models/Shipments/components/DocumentDrawer';
import { ShipmentExportModal } from '../models/Shipments/components/ShipmentExportModal';
import TaskDiscrepancyDrawer from '../models/Shipments/components/TaskDiscrepancyDrawer';
import convertShipmentQueryRequestToFilter, { ShipmentQueryRequest } from 'models/Shipments/utils';

const DEFAULT_FILTERS: ShipmentQueryRequest = {
  page_size: 10,
  page: 1,
  mode: [],
  client_shipment_id: [],
  validation_status: [],
  order_by: 'created_at',
  order_direction: 'desc',
  orgId: '',
};

const INITIAL_DOCUMENT_TYPES = ['master_bill_of_lading', 'house_bill_of_lading', 'pre_alert'];
const MAX_DOCUMENTS_TO_SHOW_ON_TABLE = 10;

export default function Shipments() {
  const [activeRows, setActiveRows] = useState<string[]>([]);
  const [documentDrawerOpen, setDocumentDrawerOpen] = useState(false);
  const [taskDiscrepancyDrawerOpen, setTaskDiscrepancyDrawerOpen] = useState(false);
  const [isExportAlertShown, setIsExportAlertShown] = useState(false);
  const [taskDiscrepancyId, setTaskDiscrepancy] = useState<string>('');
  const [selectedShipment, setSelectedShipment] = useState<any>();
  const [fetchShipmentsRequest, setFetchShipmentsRequest] = useState<PaginatedQueryRequest>({
    page: 1,
    page_size: 10,
    filter: [],
    sort: {
      key: 'created_at',
      value: 'desc',
    },
    orgId: '',
  });
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<string[]>(
    getStorage('table-shipment-document-types')?.selectedDocumentTypes ?? INITIAL_DOCUMENT_TYPES
  );
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [applyFilter, setApplyFilter] = useState<ShipmentQueryRequest>({
    ...DEFAULT_FILTERS,
  });
  const { stats, allClientShipmentIds, allShipmentsCount, shipments, totalPages, totalData, isLoading } = useSelector(
    (state: RootState) => state.shipments
  );
  const { update: updateLocalStorage } = useLocalStorage('table-shipments', {});
  const { update: updateLocalStorageForShipmentDocumentTypes } = useLocalStorage('table-shipment-document-types', {});

  const { mutate: findTaskDiscrepancyMutation, isPending: isFindTaskDiscrepancyPending } = useMutation({
    mutationKey: [QueryKey.taskDiscrepancy],
    mutationFn: findTaskDiscrepancy,
    onSuccess(data) {
      if (!data) {
        return;
      }
      setTaskDiscrepancy(data.id);
      setTaskDiscrepancyDrawerOpen(true);
    },
  });

  const [columns, setColumns] = useState([
    {
      accessorKey: 'client_shipment_id',
      cell: (info: any) =>
        info.getValue() ? (
          <Link to={`/shipments/${info.row.original?.id}`} target="_blank" className="text-blue-400 underline">
            {info.getValue()}
          </Link>
        ) : (
          '--'
        ),
      header: 'Client shipment ID',
      maxSize: 180,
      enableResizing: true,
      id: 'shipmentId',
      key: 'client_shipment_id',
      pdfCellWidth: 25,
      textValue: (shipment: any): string => {
        return shipment.client_shipment_id ?? '';
      },
    },
    {
      accessorFn: (row: any) => row?.data?.load_type?.toUpperCase() || '--',
      id: 'shipping_mode',
      cell: (info: any) => info.getValue(),
      header: 'Mode',
      size: 100,
      enableResizing: true,
      initialSize: 100,
      key: 'data.load_type',
      pdfCellWidth: 14,
      textValue: (shipment: any): string => {
        return shipment.data?.load_type ?? '';
      },
    },
    {
      accessorFn: (row: any) => get(row, 'data.place_of_receipt', '--'),
      id: 'place_of_receipt',
      cell: (info: any) => {
        return info.getValue();
      },
      header: 'Place of receipt',
      size: 150,
      enableResizing: true,
      initialSize: 150,
      key: 'data.place_of_receipt',
      pdfCellWidth: 30,
      textValue: (shipment: any): string => get(shipment, 'data.place_of_receipt', '--'),
      enableSorting: true,
      initialVisibility: false,
    },
    {
      accessorFn: (row: any) =>
        get(row, 'created_at', '--') !== '--'
          ? moment(get(row, 'created_at', '--')).format('MM/DD/YYYY HH:mm:ss')
          : '--',
      id: 'created_at',
      cell: (info: any) => {
        return info.getValue();
      },
      header: 'Shipment creation date',
      size: 150,
      enableResizing: true,
      initialSize: 150,
      key: 'created_at',
      textValue: (shipment: any): string =>
        get(shipment, 'created_at', '--') !== '--'
          ? moment(get(shipment, 'created_at', '--')).format('MM/DD/YYYY HH:mm:ss')
          : '--',
      enableSorting: true,
    },
    {
      accessorFn: (row: any) => get(row, 'data.house_bill_of_lading_number', '--'),
      id: 'house_bill_of_lading_number',
      cell: (info: any) => {
        return info.getValue();
      },
      header: 'HBL #',
      size: 150,
      enableResizing: true,
      initialSize: 150,
      key: 'data.house_bill_of_lading_number',
      textValue: (shipment: any): string => get(shipment, 'data.house_bill_of_lading_number', '--'),
      enableSorting: true,
    },
    {
      accessorFn: (row: any) => get(row, 'data.master_bill_of_lading_number', '--'),
      id: 'master_bill_of_lading_number',
      cell: (info: any) => {
        return info.getValue();
      },
      header: 'MBL #',
      size: 150,
      enableResizing: true,
      initialSize: 150,
      key: 'data.master_bill_of_lading_number',
      textValue: (shipment: any): string => get(shipment, 'data.master_bill_of_lading_number', '--'),
      enableSorting: true,
    },
    {
      accessorFn: (row: any) => get(row, 'data.automated_manifest_system_number', '--'),
      id: 'automated_manifest_system_number',
      cell: (info: any) => {
        return info.getValue();
      },
      header: 'AMS #',
      size: 170,
      enableResizing: true,
      initialSize: 170,
      key: 'data.automated_manifest_system_number',
      textValue: (shipment: any): string => get(shipment, 'data.automated_manifest_system_number', '--'),
      enableSorting: true,
    },
    {
      accessorFn: (row: any) => {
        return {
          pol: get(row, 'data.port_of_loading.unloc', '--'),
          etd: convertMillisecondToLocaleString(get(row, 'data.estimated_time_of_departure')),
          atd: convertMillisecondToLocaleString(get(row, 'data.actual_time_of_departure')),
        };
      },
      id: 'pol',
      cell: (info: any) => {
        const row = info.getValue();
        return <POLCell data={row} />;
      },
      header: 'POL',
      size: 150,
      enableResizing: true,
      initialSize: 150,
      key: 'data.port_of_loading.unloc',
      pdfCellWidth: 30,
      textValue: (shipment: any): string => {
        const pol = shipment?.data?.port_of_loading?.unloc || '--';
        const etd = convertMillisecondToLocaleString(get(shipment, ' data.estimated_time_of_departure')) || '--';
        const atd = convertMillisecondToLocaleString(get(shipment, 'data.actual_time_of_departure')) || '--';

        return `${pol}, ${etd}, ${atd}`;
      },
      enableSorting: true,
    },
    {
      accessorFn: (row: any) => convertMillisecondToLocaleString(get(row, 'data.estimated_time_of_departure')) || '--',
      cell: (info: any) => info.getValue(),
      header: 'ETD',
      enableResizing: true,
      size: 200,
      initialSize: 200,
      id: 'etd',
      key: 'data.estimated_time_of_departure',
      pdfCellWidth: 30,
      textValue: (shipment: any): string => {
        return convertMillisecondToLocaleString(get(shipment, 'data.estimated_time_of_departure')) || '--';
      },
      initialVisibility: false,
    },
    {
      accessorFn: (row: any) => {
        return convertMillisecondToLocaleString(get(row, 'data.actual_time_of_departure')) || '--';
      },
      cell: (info: any) => info.getValue(),
      header: 'ATD',
      enableResizing: true,
      size: 200,
      initialSize: 200,
      id: 'atd',
      key: 'data.actual_time_of_departure',
      pdfCellWidth: 30,
      textValue: (shipment: any): string => {
        return convertMillisecondToLocaleString(get(shipment, 'data.actual_time_of_departure')) || '--';
      },
      initialVisibility: false,
    },
    {
      accessorFn: (row: any) => {
        return {
          pod: get(row, 'data.port_of_discharge.unloc', '--'),
          eta: convertMillisecondToLocaleString(get(row, 'data.estimated_time_of_arrival')),
          ata: convertMillisecondToLocaleString(get(row, 'data.actual_time_of_arrival')),
        };
      },
      id: 'pod',
      cell: (info: any) => {
        const row = info.getValue();
        return <PODCell data={row} />;
      },
      header: 'POD',
      size: 150,
      enableResizing: true,
      initialSize: 150,
      key: 'data.port_of_discharge.unloc',
      pdfCellWidth: 30,
      textValue: (shipment: any): string => {
        const pod = shipment?.data?.port_of_discharge?.unloc || '--';
        const eta = convertMillisecondToLocaleString(get(shipment, 'data.estimated_time_of_arrival')) || '--';
        const ata = convertMillisecondToLocaleString(get(shipment, 'data.actual_time_of_arrival')) || '--';
        return `${pod}, ${eta}, ${ata}`;
      },
      enableSorting: true,
    },
    {
      accessorFn: (row: any) => {
        return convertMillisecondToLocaleString(get(row, 'data.estimated_time_of_arrival')) || '--';
      },
      cell: (info: any) => info.getValue(),
      header: 'ETA',
      enableResizing: true,
      size: 200,
      initialSize: 200,
      id: 'eta',
      key: 'data.estimated_time_of_arrival',
      pdfCellWidth: 30,
      textValue: (shipment: any): string => {
        return convertMillisecondToLocaleString(get(shipment, 'data.estimated_time_of_arrival')) || '--';
      },
      initialVisibility: false,
    },
    {
      id: 'ata',
      key: 'data.actual_time_of_arrival',
      header: 'ATA',
      accessorFn: (row: any) => {
        return convertMillisecondToLocaleString(get(row, 'data.actual_time_of_arrival')) || '--';
      },
      cell: (info: any) => info.getValue(),
      enableResizing: true,
      size: 200,
      initialSize: 200,
      pdfCellWidth: 30,
      textValue: (shipment: any): string => {
        return convertMillisecondToLocaleString(get(shipment, 'data.actual_time_of_arrival')) || '--';
      },
      initialVisibility: false,
    },
    {
      id: 'place_of_delivery',
      key: 'data.place_of_delivery',
      header: 'Place of delivery',
      accessorFn: (row: any) => get(row, 'data.place_of_delivery', '--'),
      cell: (info: any) => {
        return info.getValue();
      },
      size: 150,
      enableResizing: true,
      initialSize: 150,
      pdfCellWidth: 30,
      textValue: (shipment: any): string => get(shipment, 'data.place_of_delivery', '--'),
      enableSorting: true,
      initialVisibility: false,
    },
    {
      id: 'vessel',
      key: 'data.vessel_name',
      header: 'Vessel',
      accessorFn: (row: any) => get(row, 'data.vessel_name', '--'),
      cell: (info: any) => info.getValue(),
      size: 150,
      enableResizing: true,
      initialSize: 150,
      pdfCellWidth: 30,
      textValue: (shipment: any): string => get(shipment, 'data.vessel_name', '--'),
      enableSorting: true,
      initialVisibility: false,
    },
    {
      id: 'voyage',
      key: 'data.vessel_voyage_number',
      header: 'Voyage',
      accessorFn: (row: any) => get(row, 'data.vessel_voyage_number', '--'),
      cell: (info: any) => info.getValue(),
      size: 150,
      enableResizing: true,
      initialSize: 150,
      pdfCellWidth: 30,
      textValue: (shipment: any): string => get(shipment, 'data.vessel_voyage_number', '--'),
      enableSorting: true,
      initialVisibility: false,
    },
    {
      id: 'shipper',
      key: 'data.shipper',
      header: 'Shipper',
      accessorFn: (row: any) => row?.data?.shipper,
      cell: (info: any) => <AddressCell data={info.getValue()} />,
      size: 150,
      enableResizing: true,
      initialSize: 150,
      pdfCellWidth: 40,
      textValue: (shipment: any): string => get(shipment, 'data.shipper.name', '--'),
    },
    {
      id: 'consignee',
      key: 'data.consignee.name',
      header: 'Consignee',
      accessorFn: (row: any) => row?.data?.consignee,
      cell: (info: any) => <AddressCell data={info.getValue()} />,
      size: 150,
      enableResizing: true,
      initialSize: 150,
      pdfCellWidth: 30,
      textValue: (shipment: any): string => `${shipment?.data?.consignee?.name || '--'}`,
      enableSorting: true,
      initialVisibility: false,
    },
    {
      id: 'notify_party',
      key: 'data.notify_party.name',
      header: 'Notify party',
      accessorFn: (row: any) => row?.data?.notify_party,
      cell: (info: any) => <AddressCell data={info.getValue()} />,
      size: 150,
      enableResizing: true,
      initialSize: 150,
      pdfCellWidth: 30,
      textValue: (shipment: any): string => get(shipment, 'data.notify_party.name', '--'),
      enableSorting: true,
      initialVisibility: false,
    },
    {
      id: 'origin_agent',
      key: 'data.origin_agent',
      header: 'Origin agent',
      accessorFn: (row: any) => row?.data?.origin_agent,
      cell: (info: any) => <AddressCell data={info.getValue()} />,
      size: 150,
      enableResizing: true,
      initialSize: 150,
      pdfCellWidth: 30,
      textValue: (shipment: any): string => get(shipment, 'data.origin_agent.name', '--'),
      enableSorting: true,
      initialVisibility: false,
    },
    {
      accessorFn: (row: any) => row?.data?.destination_agent,
      id: 'destination_agent',
      cell: (info: any) => <AddressCell data={info.getValue()} />,
      header: 'Destination agent',
      size: 150,
      enableResizing: true,
      initialSize: 150,
      key: 'data.destination_agent',
      pdfCellWidth: 30,
      textValue: (shipment: any): string => get(shipment, 'data.destination_agent.name', '--'),
      enableSorting: true,
      initialVisibility: false,
    },
    {
      id: 'container',
      key: 'data.container',
      header: 'Containers',
      accessorFn: (row: any) => row?.data,
      cell: (info: any) => <ContainerCell shipment={info.getValue()} />,
      size: 150,
      enableResizing: true,
      enableSorting: false,
      initialSize: 150,
      pdfCellWidth: 30,
      textValue: (shipment: any): string => {
        const containers = get(shipment, 'data.ocean_containers');
        if (isArray(containers) && containers.length > 0) {
          return compact(containers.map((container: any) => container.container_number)).join(', ');
        }
        return '--';
      },
    },
    {
      id: 'total_packages',
      key: 'data.total_packages.value',
      header: 'Total packages',
      accessorFn: (row: any) => get(row, 'data.total_packages'),
      cell: (info: any) => {
        const data = info.getValue();
        if (!data || data.value === undefined) {
          return '--';
        }
        return data.value + ' ' + (data.unit || '');
      },
      size: 150,
      enableResizing: true,
      initialSize: 150,
      pdfCellWidth: 30,
      textValue: (shipment: any): string => {
        const data = shipment?.data?.total_packages;
        if (!data || data.value === undefined) {
          return '--';
        }
        return data.value + ' ' + (data.unit || '');
      },
      enableSorting: true,
      initialVisibility: false,
    },
    {
      id: 'total_weight',
      header: 'Total weight',
      key: 'data.total_weight.value',
      accessorFn: (row: any) => get(row, 'data.total_weight'),
      cell: (info: any) => {
        const data = info.getValue();
        if (!data || data.value === undefined) {
          return '--';
        }
        return data.value + ' ' + (data.unit || '');
      },
      size: 150,
      enableResizing: true,
      initialSize: 150,
      pdfCellWidth: 30,
      textValue: (shipment: any): string => {
        const data = shipment?.data?.total_weight;
        if (!data || data.value === undefined) {
          return '--';
        }
        return data.value + ' ' + (data.unit || '');
      },
      enableSorting: true,
      initialVisibility: false,
    },
    {
      id: 'total_volume',
      key: 'data.total_volume.value',
      header: 'Total volume',
      accessorFn: (row: any) => get(row, 'data.total_volume'),
      cell: (info: any) => {
        const data = info.getValue();
        if (!data || data.value === undefined) {
          return '--';
        }
        return data.value + ' ' + (data.unit || '');
      },
      size: 150,
      enableResizing: true,
      initialSize: 150,
      pdfCellWidth: 30,
      textValue: (shipment: any): string => {
        const data = get(shipment, 'data.total_volume');
        if (!data || data.value === undefined) {
          return '--';
        }
        return data.value + ' ' + (data.unit || '');
      },
      enableSorting: true,
      initialVisibility: false,
    },
    {
      id: 'validationStatus',
      key: 'validation_result.status_code',
      header: 'Validation status',
      accessorKey: 'validation_result.status_code',
      cell: (info: any) => {
        const isDiscrepancy = info.getValue() === 'discrepancy';
        const records = get(info.row.original, 'validation_result.records', []);
        const count =
          isDiscrepancy && isArray(records)
            ? records.filter((record: any) => record.status === 'discrepancy').length
            : undefined;

        return (
          <Status
            type="Button"
            textClassName="!text-sm"
            text={info.getValue() as string}
            count={count}
            disabled={disabledStatus.includes(info.getValue() as string) || isFindTaskDiscrepancyPending}
            handleClick={() => {
              if (isDiscrepancy) {
                setActiveRows([info.row.original.id]);
                findTaskDiscrepancyMutation(info.row.original.client_shipment_id);
              } else {
                handleRedirectShipmentDetails(info.row.original.id);
              }
            }}
            target={info.getValue() as string}
          />
        );
      },
      size: 200,
      initialSize: 200,
      pdfCellWidth: 20,
      textValue: (shipment: any): string => {
        const status = get(shipment, 'validation_result.status_code') ?? '';
        const optionMaybe = VALIDATION_STATUS_OPTIONS.find((option) => option.value === status);
        return optionMaybe?.label || status || '--';
      },
    },
    {
      id: 'documents',
      header: 'Documents',
      key: 'documents',
      accessorFn: (row: any) => {
        const items: {
          id?: string;
          type: string;
          status: string;
          name: string;
        }[] = [];
        if (isArray(row?.shipping_documents)) {
          row.shipping_documents.forEach((doc: any) => {
            const type: keyof typeof EDocumentType = doc.type;
            items.push({
              id: doc.id,
              type: type,
              name: EDocumentType[type] || '',
              status: doc.processing_result_status_code,
            });
          });
        }

        if (isArray(row?.unreceived_shipping_document_types)) {
          row.unreceived_shipping_document_types.forEach((doc: string) => {
            items.push({
              type: doc,
              name: EDocumentType[doc as TDocumentType] || '',
              status: 'missing',
            });
          });
        }
        return items;
      },
      cell: (info: any) => {
        const shippingDocuments = info.getValue();
        let existedInData = 0;
        const preferredDocumentsData = flatten(
          info.selectedDocumentTypes.map((type: string) => {
            const docs = shippingDocuments.reduce((acc: any, doc: any) => {
              if (doc.type === type) {
                acc.push({
                  id: doc.id,
                  name: doc.name,
                  status: doc.status,
                  link: doc.id
                    ? `/document/${doc.id}?client-shipment-id=${info.row.original.client_shipment_id}`
                    : undefined,
                });
              }
              return acc;
            }, []);

            if (docs.length > 0) {
              existedInData += docs.length;
              return docs;
            } else {
              return {
                id: undefined,
                name: EDocumentType[type as TDocumentType] || '',
                status: 'missing',
                link: undefined,
              };
            }
          })
        ).sort(sortDocumentByTypeCB);

        const notPreferredItems = shippingDocuments.length - existedInData;
        const hiddenPreferredItems =
          preferredDocumentsData.length >= MAX_DOCUMENTS_TO_SHOW_ON_TABLE
            ? preferredDocumentsData.length - MAX_DOCUMENTS_TO_SHOW_ON_TABLE
            : 0;
        const hiddenItems = notPreferredItems + hiddenPreferredItems;

        return (
          <div className="flex min-w-108 flex-wrap gap-x-3 gap-y-3">
            {preferredDocumentsData.slice(0, MAX_DOCUMENTS_TO_SHOW_ON_TABLE).map((data: any, index: number) => (
              <DocumentChip key={index} {...data} />
            ))}
            {hiddenItems > 0 && (
              <button
                className="rounded-full border border-gray-500 px-4 py-1 text-sm font-medium text-gray-500"
                type="button"
                onClick={() => {
                  setSelectedShipment(info.row.original);
                  setActiveRows([info.row.original.id]);
                  setDocumentDrawerOpen(true);
                }}
              >
                {hiddenItems} more
              </button>
            )}
          </div>
        );
      },
      size: 432,
      minSize: 250,
      enableResizing: true,
      enableSorting: false,
      pdfCellWidth: 45,
      textValue: (shipment: any): string => {
        const documentsMaybe = shipment.shipping_documents;
        if (isArray(documentsMaybe)) {
          return documentsMaybe
            .map((doc: any) => {
              const type: keyof typeof EDocumentType = doc.type;
              return EDocumentType[type] ?? '';
            })
            .join(', ');
        }
        return '--';
      },
    },
  ]);

  useShipments(fetchShipmentsRequest);

  const handleRedirectShipmentDetails = (shipmentId: string) => {
    if (shipmentId) window.open(`${window.location.href}/${shipmentId || ''}`, '_blank');
  };

  const resetFilter = () => {
    handleApplyFilters({
      ...DEFAULT_FILTERS,
    });
  };

  function filterCount(filter: ShipmentQueryRequest) {
    let count = 0;

    const filterKeys = Object.keys(filter) as (keyof ShipmentQueryRequest)[];

    filterKeys.forEach((key) => {
      if (key === 'page_size' || key === 'page' || key === 'order_by' || key === 'order_direction' || key === 'orgId') {
        return;
      }

      const value = filter[key];

      if (!value) {
        return;
      }

      if (Array.isArray(value) && value.length > 0) {
        count++;
        return;
      }

      if (typeof value === 'string' && value.trim().length > 0) {
        count++;
        return;
      }

      if (typeof value === 'object' && !Array.isArray(value)) {
        count++;
        return;
      }
    });

    return count;
  }

  const filterItems: IFilterItem[] = [
    {
      key: 'client_shipment_id',
      title: 'Client shipment ID',
      type: 'dropdown',
      options: allClientShipmentIds.map((id) => ({ value: id, label: id })),
      onChange: (value: string[]) => {},
      onClear: () => handleClearFilter('client_shipment_id', []),
      value: applyFilter.client_shipment_id || [],
      activeValue: applyFilter.client_shipment_id || [],
      placeholder: 'Search',
      disabled: false,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && (!filters.client_shipment_id || filters.client_shipment_id.length === 0);
      },
    },
    {
      key: 'mode',
      title: 'Mode',
      options: MODE_OPTIONS.slice(0),
      onChange: (value: string[]) => {},
      value: applyFilter.mode || [],
      activeValue: applyFilter.mode || [],
      type: 'checkbox',
      onClear: () => {
        handleClearFilter('mode', []);
      },
      disabled: false,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && (!filters.mode || filters.mode.length === 0);
      },
      columns: 3,
    },
    {
      key: 'validation_status',
      title: 'Validation status',
      options: VALIDATION_STATUS_OPTIONS.slice(0),
      onChange: (value: string[]) => {},
      value: applyFilter.validation_status || [],
      activeValue: applyFilter.validation_status || [],
      type: 'checkbox',
      onClear: () => {
        handleClearFilter('validation_status', []);
      },
      disabled: false,
      disabledChecker: (filters: any) =>
        filterCount(filters) > 0 && (!filters.validation_status || filters.validation_status.length === 0),
    },
    {
      key: 'place_of_receipt',
      title: 'Place of receipt',
      type: 'inputSearch',
      onChange: (value: string) => {},
      onClear: () => {
        handleClearFilter('place_of_receipt', '');
      },
      value: applyFilter.place_of_receipt ?? '',
      activeValue: applyFilter.place_of_receipt ?? '',
      placeholder: 'Search',
      disabled: filterCount(applyFilter) > 0 && !applyFilter.place_of_receipt,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && !filters.place_of_receipt;
      },
    },
    {
      key: 'created_at',
      title: 'Shipment creation date',
      type: 'dateDropdown',
      value: undefined,
      activeValue: applyFilter.created_at?.label || '',
      onChange(value) {},
      onClear() {
        handleClearFilter('created_at', undefined);
      },
      disabled: false,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && !filters.created_at;
      },
    },
    {
      key: 'house_bill_of_lading_number',
      title: 'HBL #',
      type: 'inputSearch',
      onChange: (value: string) => {},
      onClear: () => {
        handleClearFilter('house_bill_of_lading_number', '');
      },
      value: applyFilter.house_bill_of_lading_number ?? '',
      activeValue: applyFilter.house_bill_of_lading_number ?? '',
      placeholder: 'Search HBL #',
      disabled: false,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && !filters.house_bill_of_lading_number;
      },
    },
    {
      key: 'master_bill_of_lading_number',
      title: 'MBL #',
      type: 'inputSearch',
      onChange: (value: string) => {},
      onClear: () => {
        handleClearFilter('master_bill_of_lading_number', '');
      },
      value: applyFilter.master_bill_of_lading_number ?? '',
      activeValue: applyFilter.master_bill_of_lading_number ?? '',
      placeholder: 'Search MBL #',
      disabled: false,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && !filters.master_bill_of_lading_number;
      },
    },
    {
      key: 'automated_manifest_system_number',
      title: 'AMS #',
      type: 'inputSearch',
      onChange: (value: string) => {},
      onClear: () => {
        handleClearFilter('automated_manifest_system_number', '');
      },
      value: applyFilter.automated_manifest_system_number ?? '',
      activeValue: applyFilter.automated_manifest_system_number ?? '',
      placeholder: 'Search AMS #',
      disabled: false,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && !filters.automated_manifest_system_number;
      },
    },
    {
      key: 'pol',
      title: 'Port of loading (POL)',
      type: 'inputSearch',
      onChange: (value: string) => {},
      onClear: () => {
        handleClearFilter('pol', '');
      },
      value: applyFilter.pol ?? '',
      activeValue: applyFilter.pol ?? '',
      placeholder: 'Search POL',
      disabled: false,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && !filters.pol;
      },
    },
    {
      key: 'estimated_time_of_departure',
      title: 'ETD',
      type: 'dateDropdown',
      value: undefined,
      activeValue: applyFilter.estimated_time_of_departure?.label || '',
      onChange(value) {},
      onClear: () => handleClearFilter('estimated_time_of_departure', undefined),
      disabled: false,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && !filters.estimated_time_of_departure;
      },
    },
    {
      key: 'actual_time_of_departure',
      title: 'ATD',
      type: 'dateDropdown',
      value: undefined,
      activeValue: applyFilter.actual_time_of_departure?.label || '',
      onChange(value) {},
      onClear: () => handleClearFilter('actual_time_of_departure', undefined),
      disabled: false,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && !filters.actual_time_of_departure;
      },
    },
    {
      key: 'pod',
      title: 'POD',
      type: 'inputSearch',
      onChange: (value: string) => {},
      onClear: () => handleClearFilter('pod', ''),
      value: applyFilter.pod ?? '',
      activeValue: applyFilter.pod ?? '',
      placeholder: 'Search POD',
      disabled: false,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && !filters.pod;
      },
    },
    {
      key: 'estimated_time_of_arrival',
      title: 'ETA',
      type: 'dateDropdown',
      value: undefined,
      activeValue: applyFilter.estimated_time_of_arrival?.label || '',
      onChange(value) {},
      onClear: () => handleClearFilter('estimated_time_of_arrival', undefined),
      disabled: false,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && !filters.estimated_time_of_arrival;
      },
    },
    {
      key: 'actual_time_of_arrival',
      title: 'ATA',
      type: 'dateDropdown',
      value: undefined,
      activeValue: applyFilter.actual_time_of_arrival?.label || '',
      onChange(value) {},
      onClear: () => handleClearFilter('actual_time_of_arrival', undefined),
      disabled: false,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && !filters.actual_time_of_arrival;
      },
    },
    {
      key: 'place_of_delivery',
      title: 'Place of delivery',
      type: 'inputSearch',
      value: applyFilter?.place_of_delivery || '',
      activeValue: applyFilter?.place_of_delivery || '',
      onChange(value) {},
      onClear: () => handleClearFilter('place_of_delivery', undefined),
      disabled: false,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && !filters.place_of_delivery;
      },
    },
    {
      key: 'vessel_name',
      title: 'Vessel',
      type: 'inputSearch',
      value: applyFilter?.vessel_name || '',
      activeValue: applyFilter?.vessel_name || '',
      onChange(value) {},
      onClear: () => handleClearFilter('vessel_name', undefined),
      disabled: false,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && !filters.vessel_name;
      },
    },
    {
      key: 'vessel_voyage_number',
      title: 'Voyage',
      type: 'inputSearch',
      value: applyFilter?.vessel_voyage_number || '',
      activeValue: '',
      onChange(value) {},
      onClear: () => handleClearFilter('vessel_voyage_number', undefined),
      disabled: false,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && !filters.vessel_voyage_number;
      },
    },
    {
      key: 'shipper_name',
      title: 'Shipper',
      type: 'inputSearch',
      value: applyFilter?.shipper_name || '',
      activeValue: applyFilter?.shipper_name || '',
      onChange(value) {},
      onClear: () => handleClearFilter('shipper_name', undefined),
      disabled: false,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && !filters.shipper_name;
      },
    },
    {
      key: 'consignee_name',
      title: 'Consignee',
      type: 'inputSearch',
      value: applyFilter?.consignee_name || '',
      activeValue: applyFilter?.consignee_name || '',
      onChange(value) {},
      onClear: () => handleClearFilter('consignee_name', undefined),
      disabled: false,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && !filters.consignee_name;
      },
    },
    {
      key: 'notify_party',
      title: 'Notify party',
      type: 'inputSearch',
      value: applyFilter?.notify_party || '',
      activeValue: applyFilter?.notify_party || '',
      onChange(value) {},
      onClear: () => handleClearFilter('notify_party', undefined),
      disabled: false,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && !filters.notify_party;
      },
    },
    {
      key: 'destination_agent',
      title: 'Destination agent',
      type: 'inputSearch',
      value: applyFilter?.destination_agent || '',
      activeValue: applyFilter?.destination_agent || '',
      onChange(value) {},
      onClear: () => handleClearFilter('destination_agent', undefined),
      disabled: false,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && !filters.destination_agent;
      },
    },
    {
      key: 'container_number',
      title: 'Containers',
      type: 'inputSearch',
      value: applyFilter?.container_number || '',
      activeValue: applyFilter?.container_number || '',
      onChange(value) {},
      onClear: () => handleClearFilter('container_number', undefined),
      disabled: false,
      disabledChecker: (filters: any) => {
        return filterCount(filters) > 0 && !filters.container_number;
      },
    },
  ];

  const hasSingleValidationStatus = applyFilter.validation_status?.length === 1;
  const includeDiscrepancy = applyFilter.validation_status?.includes('discrepancy');
  const includePartialOk = applyFilter.validation_status?.includes('partial_ok');
  const includeMissingDocuments = hasSingleValidationStatus && includePartialOk;
  const includeInprogress = applyFilter.validation_status?.length === 2 && includePartialOk && includeDiscrepancy;
  const includeCompleted = hasSingleValidationStatus && applyFilter.validation_status?.includes('all_ok');

  const activeFilterCount = useMemo(
    () =>
      filterItems.filter((item) => {
        if (!item.activeValue) return false;
        const isArray = Array.isArray(item.activeValue);
        if (isArray && item.activeValue.length === 0) return false;
        if (item.type === 'dateRange' && item.activeValue?.filter((v: any) => v).length === 0) return false;
        return true;
      }).length,
    [filterItems]
  );

  const handleClearFilter = (key: keyof ShipmentQueryRequest, value: any) => {
    handleApplyFilters({
      ...applyFilter,
      [key]: value,
    });
  };

  const handleApplyFilters = (filter: ShipmentQueryRequest) => {
    const filters = convertShipmentQueryRequestToFilter(filter);
    const previousPage = applyFilter.page || 1;
    const page_size = filter.page_size || 10;

    setFetchShipmentsRequest({
      ...fetchShipmentsRequest,
      page: filter.page || 1,
      page_size,
      sort: {
        key: (filter.order_by && (shipmentSortingColIdMap as any)?.[filter.order_by]) || 'created_at',
        value: (filter.order_direction || 'desc') as SortMode,
      },
      filter: filters as Filter[],
      direction: previousPage === filter.page ? undefined : previousPage < filter.page ? 'next' : 'prev',
    });
    setApplyFilter({
      ...filter,
    });
  };

  const handleRemoveValidationStatusFilter = (value: string[]) => {
    handleApplyFilters({
      ...DEFAULT_FILTERS,
      validation_status: applyFilter.validation_status?.filter((status) => !value.includes(status)),
    });
  };

  const handleSelectValidationStatusFilter = (value: string[]) => {
    handleApplyFilters({
      ...DEFAULT_FILTERS,
      validation_status: value,
    });
  };

  const handleTaskDiscrepancyDrawerOpen = (value: boolean) => {
    if (!value) {
      setActiveRows([]);
    }
    setTaskDiscrepancyDrawerOpen(value);
  };

  const btn_jsx = (
    <Button color="white" onClick={() => setIsExportModalOpen(true)}>
      <FileSaveIcon className="!text-white" /> Export
    </Button>
  );

  const handleChangeColumnsVisibility = (columnVisibility: Record<string, boolean>) => {
    updateLocalStorage('columnVisibility', columnVisibility);
  };

  const handleSelectedDocumentsChange = (value: string[]) => {
    updateLocalStorageForShipmentDocumentTypes('selectedDocumentTypes', value);
    setSelectedDocumentTypes(value);
  };

  useEffect(() => {
    if (!documentDrawerOpen) {
      setActiveRows([]);
    }
  }, [documentDrawerOpen]);

  return (
    <main className="">
      {isExportAlertShown && <ExportPreparedAlert onDismiss={() => setIsExportAlertShown(false)} />}
      <div className="grid grid-cols-2 gap-6 md:grid-cols-5">
        <StatCard
          icon={<ShipmentStatIcon variant="hasDiscrepancies" />}
          text="Has data discrepancies"
          loading={stats?.loading}
          count={stats?.needsAttention ?? 0}
          onClick={() => {
            if (includeDiscrepancy) {
              handleRemoveValidationStatusFilter(['discrepancy']);
            } else {
              handleSelectValidationStatusFilter(['discrepancy']);
            }
          }}
          active={hasSingleValidationStatus && includeDiscrepancy}
        />
        <StatCard
          icon={<ShipmentStatIcon variant="missingDocuments" />}
          text="Awaiting required documents"
          loading={stats?.loading}
          count={stats?.missingDocuments ?? 0}
          onClick={() => {
            if (includeMissingDocuments) {
              handleRemoveValidationStatusFilter(['partial_ok']);
            } else {
              handleSelectValidationStatusFilter(['partial_ok']);
            }
          }}
          active={includeMissingDocuments}
        />
        <StatCard
          icon={<ShipmentStatIcon variant="inprogress" />}
          text="In progress"
          loading={stats.loading}
          count={stats.inProgress ?? 0}
          onClick={() => {
            if (includeInprogress) {
              handleRemoveValidationStatusFilter(['partial_ok', 'discrepancy']);
            } else {
              handleSelectValidationStatusFilter(['partial_ok', 'discrepancy']);
            }
          }}
          active={includeInprogress}
        />
        <StatCard
          icon={<ShipmentStatIcon variant="completed" />}
          text="Completed"
          loading={stats.loading}
          count={stats.completed ?? 0}
          onClick={() => {
            if (includeCompleted) {
              handleRemoveValidationStatusFilter(['all_ok']);
            } else {
              handleSelectValidationStatusFilter(['all_ok']);
            }
          }}
          active={includeCompleted}
        />
        <StatCard
          icon={<ShipmentStatIcon variant="all" />}
          text="All"
          loading={stats?.loading}
          count={allShipmentsCount ?? 0}
          onClick={() => resetFilter()}
        />
      </div>

      <DataTableV2
        tableId="shipments"
        title="Shipments"
        className="mt-6"
        columns={columns}
        data={shipments}
        totalPages={totalPages}
        totalCount={totalData || 0}
        loading={isLoading}
        buttonGroup={btn_jsx}
        applyFilter={applyFilter}
        activeFilters={
          <ActiveFiltersV2
            filterItems={filterItems}
            filters={applyFilter}
            handleApply={(filter) => {
              handleApplyFilters({
                ...applyFilter,
                ...filter,
                page: 1,
              });
            }}
            resetFilter={resetFilter}
          />
        }
        setFilter={setApplyFilter}
        setColumns={setColumns}
        filterItems={filterItems}
        filterCount={activeFilterCount}
        resetFilter={resetFilter}
        currentPage={applyFilter.page || 1}
        handleApply={handleApplyFilters}
        onRowClick={() => {}}
        onChangeColumnsVisibility={handleChangeColumnsVisibility}
        emptyContent={{
          title: filterCount(applyFilter) ? 'Result Not Found' : 'No Shipment Available',
          description: filterCount(applyFilter)
            ? 'Your search did not return any results.\nPlease try refining your search criteria.'
            : 'There are currently no shipment. Please check back later or explore other sections.',
          action: filterCount(applyFilter) ? (
            <Button onClick={resetFilter} className="!px-8">
              Clear
            </Button>
          ) : undefined,
        }}
        filterAlertChecker={(filter: any) => filterCount(filter) > 0}
        filterAlert={{
          color: 'info',
          message: 'Only one filter can be used at a time.',
        }}
        extraState={{
          selectedDocumentTypes,
        }}
        extraColumnConfigurationChildren={{
          documents: (
            <div className="mt-2 rounded-lg bg-indigo-50 px-4 py-3">
              <p className="text-[12px] font-normal text-gray-600">
                Search and select document type to display in table
              </p>

              <div className="mt-1.5">
                <FilterItemDropdown
                  extraComponent={
                    selectedDocumentTypes.length > 0 && (
                      <div className="mt-2 text-[12px] font-normal text-gray-600">Displayed in table:</div>
                    )
                  }
                  data={{
                    placeholder: 'Search by document type',
                    key: 'selected_documents',
                    title: 'Validation status',
                    options: DOCUMENT_TYPES_OPTIONS,
                    onChange: (value: string[]) => handleSelectedDocumentsChange(value),
                    value: selectedDocumentTypes,
                    type: 'checkbox',
                    onClear: () => setSelectedDocumentTypes([]),
                    disabled: false,
                  }}
                />
              </div>
            </div>
          ),
        }}
        activeRows={activeRows}
      />
      <ShipmentExportModal
        isOpen={isExportModalOpen}
        setIsOpen={setIsExportModalOpen}
        columns={columns}
        filterRequest={applyFilter}
        columnVisibility={getStorage('table-shipments')?.columnVisibility ?? []}
        onPrepareExport={() => {
          setIsExportAlertShown(true);
        }}
        onExportFinished={() => {}}
      />
      <DocumentDrawer
        open={documentDrawerOpen}
        setOpen={setDocumentDrawerOpen}
        shipment={selectedShipment}
        selectedDocumentTypes={selectedDocumentTypes}
      />
      <TaskDiscrepancyDrawer
        open={taskDiscrepancyDrawerOpen}
        setOpen={handleTaskDiscrepancyDrawerOpen}
        taskId={taskDiscrepancyId}
      />
    </main>
  );
}
