import React from 'react';

type HeaderShipmentIconProps = {
  variant?: 'default' | 'active';
};

const HeaderShipmentIcon: React.FC<HeaderShipmentIconProps> = ({ variant = 'default' }) => {
  if (variant === 'active') {
    return (
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.33801 5.1279L9.1665 6.5H16.1665L15.995 5.1279C15.8386 3.87661 15.7604 3.25097 15.335 2.87548C14.9097 2.5 14.2792 2.5 13.0182 2.5H12.3148C11.0538 2.5 10.4233 2.5 9.99797 2.87548C9.57263 3.25097 9.49442 3.87661 9.33801 5.1279Z"
          fill="#E5E7EB"
        />
        <path
          d="M3.82384 15.2748C4.24936 16.4728 5.24864 18.7336 6.6665 20.5C7.14798 20.821 8.27855 21.4511 9.076 21.4973C9.12893 21.5004 9.1554 21.5019 9.18061 21.5021C9.20581 21.5023 9.22271 21.5016 9.2565 21.5001C9.50838 21.4893 10.2939 21.3862 11.6777 20.609C12.204 20.3134 12.4671 20.1656 12.7102 20.1572C12.9534 20.1488 13.2056 20.2685 13.7102 20.5077C14.7142 20.9838 15.8791 21.5 16.1665 21.5C16.6665 21.5 17.9998 20.8333 18.6665 20.5C20.0844 18.7336 21.0836 16.4728 21.5092 15.2748C21.6441 14.8949 21.7116 14.705 21.6332 14.5234C21.5549 14.3419 21.3627 14.2568 20.9785 14.0866L13.4893 10.7692C13.0841 10.5897 12.8815 10.5 12.6665 10.5C12.4515 10.5 12.2489 10.5897 11.8437 10.7692L4.35453 14.0866C3.97027 14.2568 3.77813 14.3419 3.69979 14.5234C3.62144 14.705 3.6889 14.8949 3.82384 15.2748Z"
          fill="#E5E7EB"
        />
        <path
          d="M9.1665 6.5L9.33801 5.1279C9.49442 3.87661 9.57263 3.25097 9.99797 2.87548C10.4233 2.5 11.0538 2.5 12.3148 2.5H13.0182C14.2792 2.5 14.9097 2.5 15.335 2.87548C15.7604 3.25097 15.8386 3.87661 15.995 5.1279L16.1665 6.5"
          stroke="#111928"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.6665 21.494C3.961 21.5899 5.05017 20.5 5.99983 20.5C6.9495 20.5 8.49123 21.5053 9.33317 21.494C10.3435 21.5025 11.5269 20.5 12.6665 20.5C13.8061 20.5 14.9895 21.5025 15.9998 21.494C17.2943 21.5899 18.3835 20.5 19.3332 20.5C20.2828 20.5 21.8246 21.5053 22.6665 21.494"
          stroke="#111928"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.6665 20.5C5.24864 18.7336 4.24936 16.4728 3.82384 15.2748C3.6889 14.8949 3.62144 14.705 3.69979 14.5234C3.77813 14.3419 3.97027 14.2568 4.35453 14.0866L11.8437 10.7692C12.2489 10.5897 12.4515 10.5 12.6665 10.5C12.8815 10.5 13.0841 10.5897 13.4893 10.7692L20.9785 14.0866C21.3627 14.2568 21.5549 14.3419 21.6332 14.5234C21.7116 14.705 21.6441 14.8949 21.5092 15.2748C21.0836 16.4728 20.0844 18.7336 18.6665 20.5"
          stroke="#111928"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.6665 13L6.88241 10.1932C7.01718 8.44115 7.08457 7.56511 7.65966 7.03256C8.23476 6.5 9.11338 6.5 10.8706 6.5H14.4624C16.2196 6.5 17.0982 6.5 17.6733 7.03256C18.2484 7.56511 18.3158 8.44115 18.4506 10.1932L18.6665 13"
          stroke="#111928"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.6665 21.494C3.961 21.5899 5.05017 20.5 5.99983 20.5C6.9495 20.5 8.49123 21.5053 9.33317 21.494C10.3435 21.5025 11.5269 20.5 12.6665 20.5C13.8061 20.5 14.9895 21.5025 15.9998 21.494C17.2943 21.5899 18.3835 20.5 19.3332 20.5C20.2828 20.5 21.8246 21.5053 22.6665 21.494"
        stroke="#9CA3AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.6665 20.5C5.24864 18.7336 4.24936 16.4728 3.82384 15.2748C3.6889 14.8949 3.62144 14.705 3.69979 14.5234C3.77813 14.3419 3.97027 14.2568 4.35453 14.0866L11.8437 10.7692C12.2489 10.5897 12.4515 10.5 12.6665 10.5C12.8815 10.5 13.0841 10.5897 13.4893 10.7692L20.9785 14.0866C21.3627 14.2568 21.5549 14.3419 21.6332 14.5234C21.7116 14.705 21.6441 14.8949 21.5092 15.2748C21.0836 16.4728 20.0844 18.7336 18.6665 20.5"
        stroke="#9CA3AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.6665 13L6.88241 10.1932C7.01718 8.44115 7.08457 7.56511 7.65966 7.03256C8.23476 6.5 9.11338 6.5 10.8706 6.5H14.4624C16.2196 6.5 17.0982 6.5 17.6733 7.03256C18.2484 7.56511 18.3158 8.44115 18.4506 10.1932L18.6665 13"
        stroke="#9CA3AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.1665 6.5L9.33801 5.1279C9.49442 3.87661 9.57263 3.25097 9.99797 2.87548C10.4233 2.5 11.0538 2.5 12.3148 2.5H13.0182C14.2792 2.5 14.9097 2.5 15.335 2.87548C15.7604 3.25097 15.8386 3.87661 15.995 5.1279L16.1665 6.5"
        stroke="#9CA3AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default HeaderShipmentIcon;
