import React from 'react';

type Props = {
  className?: string;
};

const ChevronDownIcon = (props: Props) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.98675 3C6.27317 3.00006 6.54782 3.10523 6.75031 3.2924L11.0703 7.28423C11.1735 7.37628 11.2557 7.4864 11.3123 7.60816C11.3689 7.72991 11.3987 7.86086 11.4 7.99337C11.4012 8.12588 11.3739 8.25729 11.3196 8.37994C11.2653 8.50258 11.1851 8.614 11.0837 8.7077C10.9823 8.80141 10.8617 8.87551 10.729 8.92568C10.5963 8.97586 10.454 9.00111 10.3106 8.99996C10.1672 8.99881 10.0255 8.97128 9.89376 8.91898C9.76199 8.86668 9.64282 8.79065 9.54319 8.69533L5.98675 5.40907L2.43032 8.69533C2.22662 8.87712 1.95381 8.97771 1.67064 8.97543C1.38747 8.97316 1.11659 8.86821 0.916351 8.68318C0.71611 8.49815 0.602528 8.24785 0.600066 7.98619C0.597606 7.72453 0.706464 7.47244 0.903195 7.28422L5.2232 3.2924C5.42569 3.10523 5.70034 3.00006 5.98675 3Z"
        fill="#6B7280"
      />
    </svg>
  );
};

export default ChevronDownIcon;
