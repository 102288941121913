import React from 'react';
import { Link } from 'react-router-dom';

import { EDocumentType } from 'shared/constants/enums';
import { TDocumentType } from 'shared/constants/types';
import FileStatusIcon from 'shared/icons/FileStatusIcon';
import { cn } from 'shared/utils';

const styles = {
  success: 'border-green-600 text-green-600',
  error: 'cursor-not-allowed border-red-600 text-red-600',
  warning: 'border-yellow-500 text- yellow-500',
  missing: 'cursor-not-allowed border-gray-300 text-gray-400 cursor-default',
};

type DocumentChipProps = {
  name: string;
  count?: number;
  className?: string;
  link?: string;
  status?: 'missing' | 'error' | 'warning' | 'success';
};

export default function DocumentChip({ className, name, count, status = 'missing', link }: DocumentChipProps) {
  const shortenedName = EDocumentType[name as TDocumentType] || name;

  const Item = (
    <>
      <FileStatusIcon variant={status} className="size-4" />
      <span className="ml-[5px] block text-sm uppercase">{shortenedName}</span>
      {count && (
        <span className={cn('ml-[5px] block rounded-full border px-2 text-xs font-medium', styles[status])}>
          {count}
        </span>
      )}
    </>
  );

  if (link) {
    return (
      <Link
        to={link}
        target="_blank"
        rel="noreferrer"
        className={cn(
          'flex w-fit flex-grow-0 items-center rounded-md border px-2 py-1 font-medium',
          styles[status],
          className
        )}
      >
        {Item}
      </Link>
    );
  }

  return (
    <div
      className={cn(
        'flex w-fit flex-grow-0 cursor-default items-center rounded-md border px-2 py-1 font-medium',
        styles[status],
        className
      )}
    >
      {Item}
    </div>
  );
}
