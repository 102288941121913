import React, { useState } from 'react';
import { CgArrowsExpandRight } from 'react-icons/cg';
import { useParams } from 'react-router-dom';
import EmptyContent from 'shared/components/empty-content';
import { Input } from 'shared/components/input';
import { LoadingIcon } from 'shared/components/loading-icon';
import Spinner from 'shared/components/Spinner/Spinner';
import useTaskDetails from 'shared/hooks/useTaskDetails';
import { DocumentSuccess } from 'shared/icons/DocumentSuccess';
import { taskService } from 'shared/services';
import { handleErrorResponse } from 'shared/services/utils';
import { twMerge } from 'tailwind-merge';
import DetailsHeader from './Components/TaskDetailsHeader';

const TaskDetailsUnassigned: React.FC = () => {
  const { taskId } = useParams<{ taskId: string }>();
  const [refreshCount, setRefreshCount] = useState(0);
  const { task, loading, error } = useTaskDetails(taskId || '', refreshCount);
  const [shipmentId, setShipmentId] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShipmentId(e.target.value);
  };

  const handleConfirmClick = async () => {
    if (!shipmentId || isLoading || !taskId) return; // Prevent empty submission and multiple clicks
    setIsLoading(true); // Start loading
    try {
      await taskService.resolveTask({
        id: taskId,
        version: task?.version,
        result: { client_shipment_id: shipmentId },
      });
      setIsConfirmed(true);
      setRefreshCount((prev) => prev + 1);
      setErrorMessage('');
    } catch (err) {
      setErrorMessage(handleErrorResponse(err));
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  if (loading) return <Spinner />;

  if (error) {
    return <EmptyContent title="Error" description={`Error: ${error.message}`} />;
  }

  if (!task) {
    return <EmptyContent title="Task not found" className="py-8" />;
  }

  return (
    <div className="flex h-screen flex-col">
      <DetailsHeader task={task} />
      <div className="height-screen-minus-navbar container m-6 mx-auto flex flex-col items-center justify-center">
        <div className="flex h-full w-full gap-6">
          <div className="flex flex-1 flex-col overflow-y-auto rounded-lg border border-gray-200 bg-white py-4">
            <div className="flex items-center justify-between gap-4 border-b px-6 pb-4">
              <div className="flex items-center gap-2">
                <DocumentSuccess />
                <span className="text-lg font-semibold text-gray-900">Main Document</span>
              </div>
              <span className="text-xs text-gray-400">{task.data?.email_attachment_filename || ''}</span>
              <a
                href={task.downloadUrl}
                className="rounded-lg border border-gray-200 bg-white p-2"
                target="_blank"
                rel="noreferrer"
              >
                <CgArrowsExpandRight />
              </a>
            </div>
            <div className="demo-image mt-4 w-full flex-1 px-4">
              <object
                data={task.downloadUrl}
                type="application/pdf"
                width="100%"
                height="100%"
                className="h-full w-full object-contain"
              >
                <p>
                  <a href={task.downloadUrl}>See PDF</a>
                </p>
              </object>
            </div>
          </div>
          <div className="flex-1 overflow-y-auto rounded-lg border border-gray-200 bg-white py-4">
            <div className="flex items-center justify-between gap-4 border-b px-6 pb-4">
              <div className="flex items-center gap-2">
                <DocumentSuccess />
                <span className="text-lg font-semibold text-gray-900">Email Body</span>
              </div>
            </div>
            <div className="email-body mt-4 px-4">
              <p className="font-bold">{task.data.email_subject}</p>
              <p className="text-xs text-[#101828]">From: {task.data.email_sender}</p>
              <hr className="my-4" />
              <div className="text-sm">{task.data.email_body}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 mt-auto w-full border-t border-gray-200 bg-white p-4">
        <div className="container mx-auto flex flex-col gap-1">
          {errorMessage && <p className="w-full text-right text-red-500">{errorMessage}</p>}
          <div className="flex w-full items-center justify-end gap-6">
            <span className="font-semibold text-black">Enter Shipment ID</span>
            <div>
              <Input
                id="default-search"
                name="default-search"
                placeholder="TL34343434"
                required
                className="w-64 [&_input]:py-2"
                value={shipmentId}
                disabled={task?.status === 'completed'}
                onChange={handleInputChange}
              />
            </div>
            <button
              onClick={handleConfirmClick}
              className={twMerge([
                'flex items-center justify-center rounded-lg border border-gray-200 px-4 py-2 text-white',
                shipmentId && !isConfirmed && !isLoading ? 'bg-black' : 'cursor-not-allowed bg-gray-400',
              ])}
              disabled={!shipmentId || isConfirmed || isLoading || task?.status === 'completed'}
            >
              {isLoading && <LoadingIcon className="mr-2 h-5 w-5 fill-white text-gray-200" />}
              {isConfirmed ? 'Confirmed' : 'Confirm'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskDetailsUnassigned;
